import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { API_BASE_URL } from '../../../../config';

const getCourses = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/profile/courses`, {
    withCredentials: true,
  });

  return response.data;
};

export const useStudentCourses = () =>
  useQuery({
    queryKey: ['student-courses'],
    queryFn: getCourses,
    retry: false,
  });
