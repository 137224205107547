import axios from 'axios';
import { API_BASE_URL } from '../config';

const config = {
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15 * 1000,
  withCredentials: true,
};

const http = axios.create(config);

http.interceptors.request.use(
  (aConfig) => aConfig,
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const aiRequestTimeout = { timeout: 60 * 1000 };

export default http;
