import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const TEMPLATES_QUERY_KEY = 'teacherTemplates';

const getTemplates = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/templates`, { withCredentials: true });
  return response.data.templates;
};

export const useTemplates = () =>
  useQuery({
    queryKey: [TEMPLATES_QUERY_KEY],
    queryFn: getTemplates,
    retry: false,
  });
