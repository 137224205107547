import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

import 'quill/dist/quill.snow.css';

import { styled } from '@mui/material/styles';
import { SessionStatus } from './SessionStatus';
import { useGuaranteedSocketsPush } from '../hooks/useGuaranteedSocketsPush';

const QUILL_MODULES = {
  toolbar: false,
  keyboard: {
    bindings: {
      tab: {
        key: 'Tab',
        handler() {
          return true;
        },
      },
    },
  },
};

const Container = styled('div')({
  width: '100%',
  position: 'relative',
});

export function TextEditor({
  quillStyles = { height: '100%' },
  containerStyles,
  disabled,
  initialValue,
  onTextChange,
  onTextPaste,
  onFocus,
  userAssignmentId,
  step,
  active,
}) {
  const sendTextChange = useGuaranteedSocketsPush('textChange');
  const quillRef = useRef();

  const handleOnChange = (_, delta, source, editor) => {
    const text = editor.getText();
    onTextChange(text);

    if (source === 'user') {
      sendTextChange({
        delta: {
          ...delta,
          clientTime: performance.timeOrigin + performance.now(),
        },
        text,
        userAssignmentId,
        step: step || 'not-defined',
      });
    }
  };

  useEffect(() => {
    if (active && quillRef.current != null) {
      quillRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const { editor } = quillRef.current;

    const handlePaste = (event) => {
      const pastedText = event.clipboardData.getData('text');
      onTextPaste(pastedText);
    };

    if (onTextPaste) {
      editor.root.addEventListener('paste', handlePaste);
    }

    if (Array.isArray(initialValue)) {
      initialValue.forEach((delta) => {
        editor.updateContents(delta);
      });
    } else {
      editor.setContents([{ insert: initialValue || '\n' }]);
    }
    onTextChange(editor.getText());

    return () => {
      if (onTextPaste) {
        editor.root.removeEventListener('paste', handlePaste);
      }
    };
  }, [step]);

  return (
    <Container sx={containerStyles}>
      <ReactQuill
        formats={[]}
        modules={QUILL_MODULES}
        onChange={handleOnChange}
        onFocus={onFocus}
        readOnly={disabled}
        ref={quillRef}
        style={quillStyles}
        theme="snow"
      />
      <SessionStatus />
    </Container>
  );
}
