import { useState } from 'react';
import Box from '@mui/material/Box';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Navigate, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import http, { aiRequestTimeout } from '../../../../../utils/http';
import { getOutlineText } from '../../../../../helpers/template';
import { useStepsData } from '../../../../../contexts/StepsDataContext';
import Stager from './Stager';
import { ROUTES } from '../../../../../routes';
import { removeWhitespaces } from '../../../../../utils/text';

// TODO: Create hook for it like we have for other ai queries
const getAnalysis = async (text, assignmentId, grade, outlineText, outlineStructure) => {
  try {
    const { data } = await http.post(
      '/api/ai/post-analysis',
      {
        text,
        assignmentId,
        grade,
        outlineText, // TODO: we send it but we don't use it
        outlineStructure, // plain textowa wersja struktury
        clientTime: performance.timeOrigin + performance.now(),
      },
      aiRequestTimeout
    );
    return data;
  } catch (error) {
    return {
      promptResult: error.response.data?.error || 'The assigment analysis could not be processed at the moment',
      prompts: [],
    };
  }
};

// TODO: Also create hook for it?
const submitAssignment = async (assignmentId, text, writingStats) => {
  const { status } = await http.post('/api/students/assignments', { assignmentId, text, writingStats }); // TODO api path

  return status === 201;
};

export default function WritingAssignment({
  children: [Stage1Outline, Stage2Writing, Stage3Review, Stage4Complete],
  submisionData,
  useOutline,
  grade,
  templeteOutlineTree,
  outlineStructure,
  assignmentStatus,
}) {
  const [activeStage, setActiveStage] = useState(0);
  const commonStages = ['Writing', 'Review', 'Complete'];
  const stages = useOutline ? ['Outline', ...commonStages] : commonStages;
  const isOutlineStage = useOutline && activeStage === 0;
  const isWritingStage = useOutline ? activeStage === 1 : activeStage === 0;
  const isReviewStage = useOutline ? activeStage === 2 : activeStage === 1;
  const isCompleteStage = useOutline ? activeStage === 3 : activeStage === 2;
  const { user, assignmentId, writingText, writingStats } = submisionData;
  const outlineSteps = useStepsData();
  const navigate = useNavigate();

  const submissionUrl = user.isTeacher
    ? ROUTES.showAssignmentSubmission(assignmentId, user.id)
    : ROUTES.showStudentSubmission(assignmentId, user.id);

  if (!user.isTeacher && assignmentStatus === 'Submitted') {
    return <Navigate replace to={submissionUrl} />;
  }

  const nextButtonLabel = () => {
    if (isOutlineStage) return `I'm ready to write an assignment!`;
    if (isWritingStage) return `Review My Work`;
    if (isReviewStage) return `Submit My Work`;
    if (isCompleteStage) return `View Submitted Work`;

    return 'Next';
  };

  const onSubmitAssignment = async () => {
    // const { user, assignmentId, writingText, writingStats } = submisionData;
    const isCreated = await submitAssignment(assignmentId, writingText, writingStats);

    if (isCreated) {
      const outlineText = useOutline ? getOutlineText(templeteOutlineTree, outlineSteps) : '';
      getAnalysis(writingText, assignmentId, grade, outlineText, outlineStructure) // TODO: we send outlineText but do not use it
        // .then((data) => {
        //   console.log(data);
        // })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });

      enqueueSnackbar('Your writing assignment has been submitted!', { variant: 'success' });
      setActiveStage((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext = () => {
    if (isReviewStage) {
      onSubmitAssignment();
    } else if (isCompleteStage) {
      navigate(submissionUrl);
    } else {
      setActiveStage((prevActiveStage) => prevActiveStage + 1);
    }
  };

  const handleBack = () => {
    setActiveStage((prevActiveStage) => prevActiveStage - 1);
  };

  const disableGoBack = isOutlineStage || (!useOutline && isWritingStage) || isCompleteStage;

  return (
    <Box sx={{ width: '100%' }}>
      <Stager stages={stages} activeStage={activeStage} />
      <Box mt={4}>
        {isOutlineStage && Stage1Outline}
        {isWritingStage && Stage2Writing}
        {isReviewStage && Stage3Review}
        {isCompleteStage && Stage4Complete}

        <Divider sx={{ mt: 4 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowCircleLeftIcon />}
            disabled={disableGoBack}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Go Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            disabled={isReviewStage && removeWhitespaces(writingText).length === 0}
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowCircleRightIcon />}
            // sx={{ cursor: 'pointer !important' }} TODO: it does not work, add it
          >
            {nextButtonLabel()}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
