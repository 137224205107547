export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-console
      console.log('Copying to clipboard was successful!');
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.error('Could not copy text: ', err);
    }
  );
};
