import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OutlineItem } from './OutlineItem';
import { useStepsData } from '../../../../../contexts/StepsDataContext';
import { checkIfOutlineIsCompleted, createOutlineTree, getOutlineComponentsWithValues } from '../../../../../helpers/template';

// TODO: Rename templateComponents to templateComponentsWithSections or sth like that
export function OutlineTemplateItems({ userAssignmentId, templateComponents }) {
  const stepsData = useStepsData();
  const templateComponentsSteps = createOutlineTree(templateComponents); // TODO change name since steps is sth different now
  const outlineComponents = getOutlineComponentsWithValues(templateComponents, stepsData);
  const outlineReady = checkIfOutlineIsCompleted(outlineComponents);

  return (
    <>
      {templateComponentsSteps.map((section, sectionIndex) => {
        const isFirstSection = sectionIndex === 0;

        return (
          <Accordion
            defaultExpanded={isFirstSection}
            TransitionProps={{ unmountOnExit: true }}
            variant="outlined"
            key={section.name}
          >
            <AccordionSummary
              aria-controls="paragraph-step-content"
              id="paragraph-step-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h6">{section.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {section.components.map((component, componentIndex) => {
                const isFirstComponent = componentIndex === 0;
                return (
                  <OutlineItem
                    question={component.name}
                    userAssignmentId={userAssignmentId}
                    key={component.id}
                    component={component}
                    active={isFirstSection && isFirstComponent}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Alert severity={outlineReady ? 'success' : 'warning'} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        Outline {outlineReady ? 'Completed!' : 'Not Completed!'}
      </Alert>
    </>
  );
}
