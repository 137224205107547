import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const getTemplate = async ({ queryKey }) => {
  const [, { templateId }] = queryKey;

  const response = await axios.get(`${API_BASE_URL}/api/templates/${templateId}`, { withCredentials: true });

  return response.data.template;
};

export const useTemplate = (templateId) =>
  useQuery({
    enabled: !!templateId, // don't run if templateId is undefined
    queryKey: ['template', { templateId }],
    queryFn: getTemplate,
    retry: false,
  });
