import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { isEmpty } from 'ramda';
import { enqueueSnackbar } from 'notistack';

import { ROUTES } from '../../../../routes';
import { UserContext } from '../../../../contexts/UserContext';
import { useUserMutation } from '../api/useUserMutation';

const ERROR_MESSAGES = {
  email: 'You must provide the email',
  name: 'You must provide the name of the user',
};

const EMPTY_ERRORS = {
  email: '',
  name: '',
};

export function Form({ user }) {
  const navigate = useNavigate();
  const currentUser = useContext(UserContext);
  const [formErrors, setFormErrors] = useState(EMPTY_ERRORS);
  const mutation = useUserMutation({
    onSuccess: () => {
      enqueueSnackbar(
        user ? 'Changes have been saved' : 'User has been created',
        {
          autoHideDuration: 5000,
          variant: 'success',
        }
      );
      navigate(ROUTES.ADMIN_USERS);
    },
  });

  const validateForm = (values) => {
    const errors = { ...EMPTY_ERRORS };

    if (!user && isEmpty(values.email)) {
      errors.email = ERROR_MESSAGES.email;
    }

    if (isEmpty(values.name)) {
      errors.name = ERROR_MESSAGES.name;
    }

    setFormErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData);

    if (validateForm(values)) {
      mutation.mutate({
        userId: user?.id,
        data: {
          ...values, // INFO: name, email that we later on process and save in AWS Cognito
          isTeacher: Boolean(values.isTeacher),
          ...(values.isAdmin !== undefined && { isAdmin: Boolean(values.isAdmin) }),
        },
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormErrors((currentErrors) => {
      if (!isEmpty(currentErrors[e.target.name])) {
        return {
          ...currentErrors,
          [e.target.name]: '',
        };
      }

      return currentErrors;
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <TextField
        defaultValue={user?.email || ''}
        disabled={Boolean(user?.email)}
        error={!isEmpty(formErrors.email)}
        fullWidth
        helperText={formErrors.email || "This email is also user's login"}
        id="email"
        label="User's email"
        name="email"
        onChange={handleFieldChange}
        variant="filled"
      />
      <TextField
        defaultValue={user?.name || ''}
        error={!isEmpty(formErrors.name)}
        fullWidth
        helperText={formErrors.name}
        id="name"
        label="User's name"
        name="name"
        onChange={handleFieldChange}
        variant="filled"
      />
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={user?.isAdmin}
            disabled={user?.id === currentUser.id}
            name="isAdmin"
          />
        }
        label="Is admin?"
      />
      <FormControlLabel
        control={
          <Checkbox defaultChecked={user?.isTeacher ?? true} name="isTeacher" />
        }
        label="Is teacher?"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingY: 2,
          gap: 2,
        }}
      >
        <Button
          component={Link}
          disabled={mutation.isLoading}
          variant="text"
          to={ROUTES.ADMIN_USERS}
        >
          Cancel
        </Button>
        <Button disabled={mutation.isLoading} type="submit" variant="contained">
          {mutation.isLoading && (
            <CircularProgress size={15} sx={{ margin: 0.5 }} />
          )}
          {!mutation.isLoading && (user ? 'Submit changes' : 'Add user')}
        </Button>
      </Box>
    </Box>
  );
}
