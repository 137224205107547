// Maybe add sth like getEnvVarValue we use in backend or come up with a better solution
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'unknown';
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'missing sentry DSN';

export const LOGIN_TEACHER_URL = `${API_BASE_URL}/auth/login/teacher`;
export const LOGIN_STUDENT_URL = `${API_BASE_URL}/auth/login/student`;

export const EDLINK_AUTH_URL = `${API_BASE_URL}/auth/edlink`;

export const COGNITO_LOGIN_URL = `${API_BASE_URL}/auth/login`;
