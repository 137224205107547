import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_BASE_URL } from '../../../../../config';

const getEditorData = async ({ queryKey }) => {
  const [, { assignmentId, editor, userId }] = queryKey;

  const response = await axios.get(
    `${API_BASE_URL}/api/students/${userId}/assignments/${assignmentId}/steps/${editor}`,
    { withCredentials: true }
  );

  return response.data;
};

// TODO: Rename and change editor param to id or componentId or sth like that
export const useEditorData = ({ assignmentId, editor, userId }) =>
  useQuery({
    queryKey: ['editor-session', { assignmentId, editor, userId }],
    queryFn: getEditorData,
    retry: false,
  });
