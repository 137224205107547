import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { TemplateProvider } from './TemplateProvider';
import { AddButton } from '../../components/AddButton';
import { ROUTES } from '../../routes';
import { UploadButton } from '../../components/UploadButton';
import { useTemplates } from './api/useTemplates';
import { TemplatesList } from './TemplatesList';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { LoadingError } from '../../components/LoadingError';
import { TemplatePreview } from './TemplatePreview';

const theme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '36px',
          },
        },
        content: {
          margin: '1px 0px 3px 0px',
          '&.Mui-expanded': {
            margin: '1px 0px 3px 0px',
          },
        },
      },
    },
  },
});

export function TemplatesPage() {
  const { data: templates, isLoading, isError, error } = useTemplates();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  const wordpathTemplates = templates.filter((template) => template.ownerId === null);
  const userTemplates = templates.filter((template) => template.ownerId !== null);

  return (
    <TemplateProvider defaultSelectedId={userTemplates[0]?.id || wordpathTemplates[0]?.id}>
      <Container>
        <Breadcrumb />
        <Grid container gap={2}>
          <Grid item direction="column" spacing={2} xs md>
            <Accordion defaultExpanded>
              <ThemeProvider theme={theme}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ paddingX: 2, margin: 0, minHeight: '34px' }}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Link variant="h6" color="primary" href underline="none">
                      My Outline Templates
                    </Link>
                  </Box>
                </AccordionSummary>
              </ThemeProvider>
              <AccordionDetails>
                <Grid container direction="column" gap={1}>
                  <TemplatesList templates={userTemplates} sortable />
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion disabled>
              <ThemeProvider theme={theme}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ paddingX: 2, minHeight: '34px' }}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Link variant="h6" color="primary" href underline="none">
                      My Q&A Templates
                    </Link>
                  </Box>
                </AccordionSummary>
              </ThemeProvider>
              <AccordionDetails />
            </Accordion>
            <Accordion defaultExpanded>
              <ThemeProvider theme={theme}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ paddingX: 2, margin: 0, minHeight: '34px', justifyContent: 'center' }}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Link variant="h6" color="primary" href underline="none">
                      DefaultTemplates
                    </Link>
                  </Box>
                </AccordionSummary>
              </ThemeProvider>
              <AccordionDetails>
                <Grid container direction="column" gap={1}>
                  <TemplatesList templates={wordpathTemplates} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item direction="column" spacing={2} xs md>
            <TemplatePreview />
          </Grid>
        </Grid>
        <AddButton path={ROUTES.TEMPLATES_NEW} tooltip="Create New Template" />
        <UploadButton path={' '} tooltip="Upload Templete" />
      </Container>
    </TemplateProvider>
  );
}
