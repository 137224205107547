import { Container, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LoadingError } from '../../../components/LoadingError';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Form } from './components/Form';
import { useUser } from './api/useUser';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

export function EditUser() {
  const { userId } = useParams();
  const { data: user, isLoading, isError, error } = useUser(userId);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb userId={userId} username={user.name} />
      <Paper sx={{ padding: 2 }}>
        <Form user={user} />
      </Paper>
    </Container>
  );
}
