import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../routes';
import { useCourseDeleteMutation } from '../api/useCourseDeleteMutation';

export function DeleteCourseButton({ courseId }) {
  const navigate = useNavigate();
  const mutation = useCourseDeleteMutation(courseId, {
    onSuccess: () => navigate(ROUTES.CLASSES_INDEX),
  });
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        color="error"
        onClick={() => setOpen(true)}
        startIcon={<DeleteIcon />}
      >
        Delete class
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="delete-class-dialog-title"
        aria-describedby="delete-class-dialog-description"
      >
        <DialogTitle id="delete-class-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-class-dialog-description">
            Deleting class cannot be undone. Are you sure you want to delete
            this class?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutation.isLoading}
            onClick={() => setOpen(false)}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="error"
            disabled={mutation.isLoading}
            onClick={() => mutation.mutate()}
            variant="contained"
          >
            {mutation.isLoading ? (
              <CircularProgress size={15} sx={{ margin: 0.5 }} />
            ) : (
              'Delete class'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
