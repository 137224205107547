import { Container, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { ROUTES } from '../../../routes';
import { LoadingError } from '../../../components/LoadingError';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { AssignmentsList } from './components/AssignmentsList';
import { useStudentCourses } from './api/useStudentCourses';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { UserContext } from '../../../contexts/UserContext';

export function StudentDashboardPage() {
  const { courseId } = useParams();
  const { data: courses, isLoading, isError, error } = useStudentCourses();

  const user = useContext(UserContext);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !user) {
    return <LoadingError error={error} />;
  }

  if (!courseId && courses.length > 0) {
    return <Navigate replace to={ROUTES.showStudentClass(courses[0].id)} />;
  }

  const currentCourse = courses.find((item) => item.id.toString() === courseId);

  return (
    <Container>
      <Breadcrumb username={user.name} courseId={courseId} courseName={currentCourse?.name} />
      {courses.length === 0 && (
        <Paper sx={{ padding: 2 }}>
          <Typography>You are not assigned to any course yet.</Typography>
        </Paper>
      )}
      {courses.length > 0 && (
        <>
          <Tabs value={courseId} centered indicatorColor="primary" sx={{ paddingBottom: '10px' }}>
            {courses.map((course) => (
              <Tab
                key={course.id}
                component={Link}
                label={course.name}
                value={course.id.toString()}
                to={ROUTES.showStudentClass(course.id)}
              />
            ))}
          </Tabs>
          <AssignmentsList courseId={courseId} />
        </>
      )}
    </Container>
  );
}
