import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { API_BASE_URL } from '../../../../config';

const mutationFn = ({ data, promptsConfigurationId }) =>
  axios.request({
    method: 'patch',
    url: `/api/admin/prompts-configurations/${promptsConfigurationId}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
    data,
  });

export const usePromptsConfigurationUpdateMutation = ({ onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      if (response.data.user) {
        queryClient.invalidateQueries({
          queryKey: ['defaultPromptsConfiguration'],
        });
      }
      enqueueSnackbar('Changes have been saved', {
        autoHideDuration: 3000,
        variant: 'success',
      });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, {
        autoHideDuration: 5000,
        variant: 'error',
      });
      onError(error.response.data);
    },
  });
};
