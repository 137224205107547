import { append, map, when, propEq, lensProp, over, omit } from 'ramda';

export const addComponent = (sectionId, newComponentId, sections) => {
  const newComponent = {
    id: newComponentId,
    name: 'COMPONENT NAME',
    shortDescription: '',
  };

  const addComponentToSection = over(lensProp('components'), append(newComponent));
  const updateSection = when(propEq('id', sectionId), addComponentToSection);
  return map(updateSection, sections);
};

export const deleteComponent = (sectionId, componentId, sections) =>
  sections.map((section) => {
    if (section.id === sectionId) {
      return {
        ...section,
        components: section.components.filter((component) => component.id !== componentId),
      };
    }
    return section;
  });

export const duplicateComponent = (sectionId, componentId, newComponentId, sections) => {
  const sectionIndex = sections.findIndex((section) => section.id === sectionId);
  if (sectionIndex === -1) {
    return sections;
  }

  const componentIndex = sections[sectionIndex].components.findIndex((component) => component.id === componentId);
  if (componentIndex === -1) {
    return sections;
  }

  const componentToCopy = sections[sectionIndex].components[componentIndex];
  const newComponent = { ...componentToCopy, id: newComponentId, componentId: undefined };

  // Insert the cloned component at the next position
  const newSections = [...sections];
  newSections[sectionIndex].components.splice(componentIndex + 1, 0, newComponent);

  return newSections;
};

export const duplicateSection = (sectionId, newSectionId, newComponentIds, sections) => {
  const sectionIndex = sections.findIndex((section) => section.id === sectionId);
  if (sectionIndex === -1) {
    return sections;
  }

  const sectionToCopy = sections[sectionIndex];
  const newSection = { ...sectionToCopy, id: newSectionId, componentId: undefined };

  // Generate new ids for the components in the copied section
  newSection.components = newSection.components.map((component, index) => ({
    ...component,
    id: newComponentIds[index],
    componentId: undefined,
  }));

  // Insert the cloned section at the next position
  const newSections = [...sections];
  newSections.splice(sectionIndex + 1, 0, newSection);

  return newSections;
};

export const addSection = (newSectionId, sections) => {
  const newSection = {
    id: newSectionId,
    name: 'SECTION NAME',
    shortDescription: '',
    components: [],
  };

  return append(newSection, sections);
};

export const updateSection = (sectionId, newComponents, sections) => {
  const updatedComponents = over(lensProp('components'), () => newComponents);
  const updatedSection = when(propEq('id', sectionId), updatedComponents);

  return map(updatedSection, sections);
};

export const deleteSection = (sectionId, sections) => sections.filter((section) => section.id !== sectionId);

export const editComponent = (id, newData, sections) =>
  sections.map((section) => {
    if (section.id === id) {
      return { ...section, ...newData };
    }

    return {
      ...section,
      components: map(
        (component) => (component.id === id ? { ...component, ...newData } : component),
        section.components
      ),
    };
  });

export const getComponentData = (componentId, sections) => {
  const s = sections.find((section) => section.id === componentId);
  if (s) {
    return s;
  }

  let foundComponent = null;
  sections.some((section) => {
    const c = section.components.find((component) => component.id === componentId);
    if (c) {
      foundComponent = c;
      return true; // stop iterating
    }
    return false; // continue iterating
  });

  return foundComponent;
};

export const buildTemplateRequestData = (sections) => {
  let componentIndex = 0;

  return sections.map((section, sectionIndex) => ({
    ...omit(['id'], section),
    orderIndex: sectionIndex,
    components: section.components.map((component) => {
      const newComponent = {
        ...omit(['id'], component),
        orderIndex: componentIndex,
      };
      componentIndex += 1;
      return newComponent;
    }),
  }));
};

export const loadDataFromTemplate = (template, generateNewSectionId, generateNewComponentId) => {
  const { templateComponents, name } = template;
  const duplicatedSections = templateComponents.filter((component) => component.parentId === null);

  const templateStructure = duplicatedSections.map((section) => ({
    id: generateNewSectionId(),
    name: section.name,
    shortDescription: section.shortDescription,
    components: templateComponents
      .filter((component) => component.parentId === section.id)
      .map((component) => ({
        id: generateNewComponentId(),
        name: component.name,
        shortDescription: component.shortDescription,
      })),
  }));

  return { templateStructure, name };
};

export const loadSectionsDataFromTemplateToEdit = (template, generateNewSectionId, generateNewComponentId) => {
  const { templateComponents } = template;
  const duplicatedSections = templateComponents.filter((component) => component.parentId === null);

  const templateStructure = duplicatedSections.map((section) => ({
    id: generateNewSectionId(),
    componentId: section.id, // TODO part1 handle it differently (for now id is for selecting on frontend and when we go through duplicate/edit the componentId is id from database)
    name: section.name,
    shortDescription: section.shortDescription,
    components: templateComponents
      .filter((component) => component.parentId === section.id)
      .map((component) => ({
        id: generateNewComponentId(),
        componentId: component.id,
        name: component.name,
        shortDescription: component.shortDescription,
      })),
  }));

  return templateStructure;
};
