import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  EditNote as EditNoteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

import { isEmpty } from 'ramda';
import { ROUTES } from '../../../routes';
import { UserContext } from '../../../contexts/UserContext';

const isAssignmentTestable = (assignment) => !isEmpty(assignment.instructions);

export function TestAssignmentMenu({ assignment }) {
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const user = useContext(UserContext);
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        aria-controls={isOpen ? 'test-assignment-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        color="info"
        disabled={!isAssignmentTestable(assignment)}
        endIcon={<KeyboardArrowDownIcon />}
        id="test-assignment"
        onClick={handleClick}
        ref={anchorRef}
      >
        Test
      </Button>
      <Menu
        id="test-assignment-menu"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        onClick={handleClose}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'test-assignment',
        }}
      >
        <MenuItem
          component={Link}
          to={ROUTES.writeAssignment(assignment.id, user.id)}
        >
          <ListItemIcon>
            <EditNoteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography>Write a test assignment</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          disabled={assignment.teacherTestStatus === 'Not started'}
          to={ROUTES.showAssignmentSubmission(assignment.id, user.id)}
        >
          <ListItemIcon>
            <AssignmentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography>Check your test work</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
