import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';

import { useForm } from 'react-hook-form';
import { usePromptsConfigurationUpdateMutation } from './api/usePromptsConfigurationUpdateMutation';
import { VariablesInfo } from './VariablesInfo';
import { CompletionApiConfig } from './CompletionApiConfig';

export function PostAnalysisPrompts({ promptsConfiguration }) {
  const { register, handleSubmit } = useForm();
  const mutation = usePromptsConfigurationUpdateMutation();
  const saving = mutation.isLoading;

  const onSubmit = (data) => {
    mutation.mutate({ data, promptsConfigurationId: promptsConfiguration.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <VariablesInfo />
        </Grid>
        <Grid item xs={7}>
          <CompletionApiConfig register={register} promptsConfiguration={promptsConfiguration} />
        </Grid>
      </Grid>
      <Typography variant="h5" component="h5" color="text.main" sx={{ mt: 5, mb: 2 }}>
        Prompts:
      </Typography>

      <TextField
        label="Analysis prompt"
        helperText="Used right after a student submits their writing. Provides additional feedback available at the Replay & Analysis page."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.analysisPrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`analysisPrompt`)}
      />

      <Button variant="contained" type="submit" disabled={saving}>
        {saving && <CircularProgress sx={{ marginRight: 2 }} size={20} />}
        Save
      </Button>
    </form>
  );
}
