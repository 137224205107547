import { Step, StepLabel, Stepper } from '@mui/material';

export default function Stager({ stages, activeStage }) {
  return (
    <Stepper activeStep={activeStage} alternativeLabel>
      {stages.map((name) => (
        <Step key={name}>
          <StepLabel>{name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
