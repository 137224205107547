import { Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { StudentRow } from './StudentRow';

export function ExistingStudentRow({
  disabled,
  id,
  name,
  student,
  onRemove,
}) {
  return (
    <StudentRow
      disabled={disabled}
      id={id}
      name={name}
      student={student}
    >
      <Button
        color="error"
        onClick={() => onRemove(student)}
        startIcon={<CloseIcon />}
        sx={{ alignSelf: 'center', justifySelf: 'stretch' }}
        variant="outlined"
      >
        Remove
      </Button>
    </StudentRow>
  );
}
