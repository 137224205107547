import { Button, styled } from '@mui/material';

export const StyledButtonBlock = styled(({ children, selected, incomplete, ...props }) => (
  <Button fullWidth variant='outlined' {...props}>
    {children}
  </Button>
))(({ theme, selected, incomplete }) => ({
  border: selected ? `2px solid ${theme.palette.primary.main}` : '2px solid lightgrey',
  borderRadius: 8,
  borderWidth: '2px',
  '&:hover': {
    borderWidth: '2px',
  },
  textTransform: 'none',
  color:  incomplete ? theme.palette.error.main : theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: selected ? 'bold' : 'normal',
  justifyContent: 'flex-start',
  padding: '0 5px 0 35px',
}));
