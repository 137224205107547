import { TableBody, TableHead } from '@mui/material';
import { Assignment as AssignmentIcon, Preview as PreviewIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { ROUTES } from '../../../../../routes';
import { ButtonWithTooltip } from '../../../../../components/layout/ButtonWithTooltip';
import { Table, Td, Th, Tr } from '../../../../../components/layout/Table';
import { IconButtonWithTooltip } from '../../../../../components/layout/IconButtonWithTooltip';

export function StudentAssignmentsList({ assignments, student, studentId }) {
  const studentNamePossession = student.name.endsWith('s') ? `'` : `'s`;

  return (
    <Table
      caption={`${student.name}${studentNamePossession} Assignments`}
      variant="outlined"
      noData={assignments.length === 0}
      ariaLabel="List of student assignments"
    >
      <TableHead>
        <Tr>
          <Th align="left">Title</Th>
          <Th>Due date</Th>
          <Th>Template</Th>
          <Th>Status</Th>
          <Th>Actions</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {assignments.map((assignment) => (
          <Tr key={assignment.id}>
            <Td align='left'>
              <ButtonWithTooltip
                label={assignment.title}
                tooltip="View Assignment"
                icon={<AssignmentIcon />}
                to={ROUTES.showAssignment(assignment.id)}
              />
            </Td>
            <Td>{assignment.dueDate ? format(assignment.dueDate, 'P') : '-'}</Td>
            <Td>TODO</Td>
            <Td>{assignment.status}</Td>
            <Td>
              {assignment.status !== 'Not started' && (
                <IconButtonWithTooltip
                  tooltip={assignment.status === 'Submitted' ? 'View submitted work' : 'View work in progress'}
                  icon={<PreviewIcon />}
                  to={ROUTES.showAssignmentSubmission(assignment.id, studentId)} // TODO: Why I can't use student.id here?
                />
                // TODO add replay & analytics button
              )}
            </Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
