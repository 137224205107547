import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

import { API_BASE_URL } from '../../../../config';

const buildMutationFn = (userId) => () =>
  axios.request({
    method: 'delete',
    url: `/api/admin/users/${userId}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
  });

export const useUserDeleteMutation = (userId, { onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: buildMutationFn(userId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      enqueueSnackbar('User has been deleted', {
        autoHideDuration: 3000,
        variant: 'success',
      });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, {
        autoHideDuration: 5000,
        variant: 'error',
      });
      onError(error.response.data);
    },
  });
};
