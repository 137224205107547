import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const getGoogleCourses = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/google-classroom/classrooms`, { withCredentials: true });

  return response.data.courses;
};

export const useGoogleCoursesList = () =>
  useQuery({
    queryKey: ['google-courses'],
    queryFn: getGoogleCourses,
    retry: false,
  });
