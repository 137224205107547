import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { API_BASE_URL } from '../../../../config';
import { parseDateWithoutTimezone } from '../../../../utils/date';

const getAssignments = async ({ queryKey }) => {
  const [, { courseId }] = queryKey;
  const response = await axios.get(
    `${API_BASE_URL}/api/profile/courses/${courseId}/assignments`,
    {
      withCredentials: true,
    }
  );

  return response.data.map((assignment) => ({
    ...assignment,
    assignedAt: assignment.assignedAt
      ? parseDateWithoutTimezone(assignment.assignedAt)
      : undefined,
    dueDate: assignment.dueDate
      ? parseDateWithoutTimezone(assignment.dueDate)
      : undefined,
  }));
};

// TODO: Today we rather talk about studen/user assignments that course assignments
export const useCourseAssignments = (courseId) =>
  useQuery({
    queryKey: ['assignments', { courseId }],
    queryFn: getAssignments,
    retry: false,
  });
