import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import useProfileDataSync from '../../../hooks/edlink/useProfileDataSync';
// import { useContext } from 'react';
// import { UserContext } from '../../../contexts/UserContext';

export default function ProfileSyncPage() {
  // const user = useContext(UserContext);
  const { mutate: syncProfileData, isLoading } = useProfileDataSync();

  useEffect(() => {
    // syncProfileData(user.id);
    syncProfileData();
  }, []);

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
        {isLoading ? (
          <>
            <CircularProgress style={{ marginBottom: '8px' }} />
            <span>Data sync in progress</span>
          </>
        ) : (
          'Data synced successfully'
        )}
      </div>
    );
}
