import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import { useUserDeleteMutation } from '../api/useUserDeleteMutation';

export function DeleteUserButton({ disabled = false, userId }) {
  const mutation = useUserDeleteMutation(userId);
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        color="error"
        disabled={disabled}
        onClick={() => setOpen(true)}
        title="Delete user"
        size='small'
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="delete-user-dialog-title"
        aria-describedby="delete-user-dialog-description"
      >
        <DialogTitle id="delete-user-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-user-dialog-description">
            Deleting a user cannot be undone. Are you sure you want to delete
            this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutation.isLoading}
            onClick={() => setOpen(false)}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="error"
            disabled={mutation.isLoading}
            onClick={() => mutation.mutate()}
            variant="contained"
          >
            {mutation.isLoading ? (
              <CircularProgress size={15} sx={{ margin: 0.5 }} />
            ) : (
              'Delete user'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
