import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

import { API_BASE_URL } from '../../../../config';

const mutationFn = ({ data, userId }) =>
  axios.request({
    method: userId ? 'patch' : 'post',
    url: `/api/admin/users/${userId || ''}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
    data,
  });

export const useUserMutation = ({ onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      if (response.data.user) {
        queryClient.invalidateQueries({
          queryKey: ['user', { userId: response.data.user.id.toString() }],
        });
      }
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, {
        autoHideDuration: 5000,
        variant: 'error',
      });
      onError(error.response.data);
    },
  });
};
