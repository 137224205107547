import { createContext, useContext, useState } from 'react';

const GwenStepContext = createContext('');
// eslint-disable-next-line no-unused-vars
const SetGwenStepContext = createContext((promptResult) => {});

export function GwenStepContextProvider({ children }) {
  const [promptResult, setPromptResult] = useState('');

  return (
    <SetGwenStepContext.Provider value={setPromptResult}>
      <GwenStepContext.Provider value={promptResult}>{children}</GwenStepContext.Provider>
    </SetGwenStepContext.Provider>
  );
}

export const useGwenStep = () => useContext(GwenStepContext);
export const useUpdateGwenStep = () => useContext(SetGwenStepContext);
