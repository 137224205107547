import { Link } from 'react-router-dom';
import { Button, Grid, Paper, Typography } from '@mui/material';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { WritingStats } from '../../components/WritingStats';
import { CompletedOutlinePreview } from '../../components/CompletedOutlinePreview';
import { createOutlineTree } from '../../../../helpers/template';
import { WordCounter } from './WordCounter';
import { ROUTES } from '../../../../routes';
import TextReadOnly from '../write/components/TextReadOnly';

export function WritingStepPreview({
  sessionUser,
  outlineSteps,
  writingText,
  templateComponents,
  useOutline,
  userAssignment,
  studentId,
}) {
  const templeteOutlineTree = createOutlineTree(templateComponents);

  return (
    <Grid container spacing={2}>
      {useOutline && (
        <Grid item md={3} xs={12}>
          <CompletedOutlinePreview templeteOutlineTree={templeteOutlineTree} outlineSteps={outlineSteps} />
        </Grid>
      )}
      <Grid item md={useOutline ? 9 : 12} xs={12}>
        <Typography sx={{ paddingBottom: 2, textAlign: 'center' }} variant="h6">
          Your writing assignment
        </Typography>
        <Paper sx={{ padding: 2, display: 'flex', minHeight: 600, mb: 2 }}>
          <TextReadOnly text={writingText} />
        </Paper>
        {sessionUser.isTeacher ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <WritingStats text={writingText} writingStats={userAssignment.writingStats} />
            </Grid>
            {sessionUser.isTeacher && (
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  component={Link}
                  startIcon={<TroubleshootIcon />}
                  to={ROUTES.showAssignmentAnalytics(userAssignment.assignmentId, studentId)}
                  sx={{ float: 'right' }}
                >
                  Replay & Analysis
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <WordCounter text={writingText} />
        )}
      </Grid>
    </Grid>
  );
}
