import { useContext } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { TemplateContext } from '../../../templates/TemplateProvider';

function TemplateListItem({ template, setTemplateId }) {
  const theme = useTheme();
  const { selectedTemplateId, setSelectedTemplateId } = useContext(TemplateContext);
  const isSelected = selectedTemplateId === template.id;

  // TODO: Refactor at least names, e.g. setContextTemplateId ? use template obj instead of id?
  const onClickHandler = () => {
    setSelectedTemplateId(template.id);
    setTemplateId(template.id);
  };

  return (
    <ListItemButton
      key={template.id}
      selected={isSelected}
      onClick={onClickHandler}
      sx={{
        paddingY: '0px',
        margin: '2px',
        border: isSelected ? `2px solid ${theme.palette.primary.main}` : '2px solid lightgrey',
        borderRadius: '5px',
      }}
    >
      <ListItemText
        primary={
          <span
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
              color: theme.palette.primary.main,
            }}
          >
            {template.name}
          </span>
        }
      />
    </ListItemButton>
  );
}

export default TemplateListItem;
