import { Typography } from '@mui/material';

export default function TextReadOnly({ text }) {
  return (
    <Typography fontSize="small" sx={{ padding: 3 }}>
      {text?.split('\n').map((fragment) => (
        <>
          {fragment}
          <br />
        </>
      ))}
    </Typography>
  );
}
