import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { isEmpty } from 'ramda';
import { enqueueSnackbar } from 'notistack';

import { ROUTES } from '../../../routes';
import { useUserMutation } from '../api/useUserMutation';

const ERROR_MESSAGES = {
  name: 'You must provide the name of the user',
};

const EMPTY_ERRORS = {
  name: '',
};

export function Form({ user }) {
  const [formErrors, setFormErrors] = useState(EMPTY_ERRORS);
  const mutation = useUserMutation({
    onSuccess: () => {
      enqueueSnackbar(
        user ? 'Changes have been saved' : 'User has been created',
        {
          autoHideDuration: 5000,
          variant: 'success',
        }
      );
    },
  });

  const validateForm = (values) => {
    const errors = { ...EMPTY_ERRORS };

    if (isEmpty(values.name)) {
      errors.name = ERROR_MESSAGES.name;
    }

    setFormErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData);

    if (validateForm(values)) {
      mutation.mutate({
        data: values,
        userId: user?.id, // TODO: looks like not needed/used - verify later
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormErrors((currentErrors) => {
      if (!isEmpty(currentErrors[e.target.name])) {
        return {
          ...currentErrors,
          [e.target.name]: '',
        };
      }

      return currentErrors;
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <TextField
        defaultValue={user.email}
        disabled
        fullWidth
        label="Your email / username"
        variant="filled"
      />
      <TextField
        defaultValue={user.name}
        error={!isEmpty(formErrors.name)}
        fullWidth
        helperText={formErrors.name}
        id="name"
        label="Your name"
        name="name"
        onChange={handleFieldChange}
        variant="filled"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingY: 2,
          gap: 2,
        }}
      >
        <Button
          component={Link}
          disabled={mutation.isLoading}
          variant="text"
          to={ROUTES.HOME}
        >
          Cancel
        </Button>
        <Button disabled={mutation.isLoading} type="submit" variant="contained">
          {mutation.isLoading ? (
            <CircularProgress size={15} sx={{ margin: 0.5 }} />
          ) : (
            'Save changes'
          )}
        </Button>
      </Box>
    </Box>
  );
}
