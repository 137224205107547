import { useNavigate, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
// import { ButtonWithTooltip } from './layout/ButtonWithTooltip';

export function GoBackButton({ sx, size = 'small', color = 'primary' }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Stack direction="row" spacing={1}>
      {/* <ButtonWithTooltip label="Go Back" icon={<ArrowBackIcon />} onClick={handleBack} size="medium" /> */}

      <Tooltip title="Go Back" placement="left">
        <Fab color={color} size={size} component={Link} sx={sx} onClick={handleBack}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </Fab>
      </Tooltip>
    </Stack>
  );
}
