import { Alert, Box, Grid, Paper, Typography } from '@mui/material';
import { useStepsData } from '../../../../../contexts/StepsDataContext';
import { removeWhitespaces } from '../../../../../utils/text';
import TextReadOnly from './TextReadOnly';
import { STEPS } from '../../../../../helpers/constants';

export function Stage3Review({ templeteOutlineTree, useOutline }) {
  const style = { backgroundColor: '#f5f5f5', paddingTop: 5, paddingBottom: 3 };

  const { [STEPS.WRITING_TEXT]: writingText, ...outlineSteps } = useStepsData();

  return (
    <Paper style={style}>
      <Box>
        {useOutline &&
          templeteOutlineTree.map((section) => (
            <Grid container alignItems="center" key={section.name}>
              <Grid item xs={1} textAlign="center">
                <Typography fontSize="small" sx={{ textTransform: 'capitalize' }}>
                  {section.name}
                </Typography>
              </Grid>
              <Grid item container xs={11} justifyContent="flex-start">
                {section.components.map((component) => (
                  <>
                    <Grid item xs={2}>
                      <Typography fontSize="small" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                        {component.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ pl: 2 }}>
                      <Typography fontSize="small">
                        {removeWhitespaces(outlineSteps[component.id]) ? (
                          outlineSteps[component.id]
                        ) : (
                          <span style={{ color: 'red' }}>Skipped</span>
                        )}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
          ))}
        {removeWhitespaces(writingText).length > 0 ? (
          <TextReadOnly text={writingText} />
        ) : (
          <Alert severity='error' sx={{ m: 3, display: 'flex', justifyContent: 'center' }}>
            You cannot submit an empty assignment!
          </Alert>
        )}
      </Box>
    </Paper>
  );
}
