import { useRef, useState } from 'react';
import { isEmpty } from 'ramda';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { StudentRow } from './StudentRow';

const EMPTY_ERRORS = { name: '' };

export function NewStudentRow({ disabled, id, name, onAdd }) {
  const [errors, setErrors] = useState(EMPTY_ERRORS);
  const student = useRef({ name: '' }).current;

  const handleChange = (field, event) => {
    setErrors((currentErrors) => ({
      ...currentErrors,
      [field]: isEmpty(event.target.value) ? `You need to fill in student's ${field}` : '',
    }));
    student[field] = event.target.value;
  };

  const handleClick = () => {
    const currentErrors = {
      name: isEmpty(student.name) ? `You need to fill in student's name` : '',
    };

    if (!isEmpty(Object.values(currentErrors).filter(Boolean))) {
      setErrors(currentErrors);
      return;
    }

    onAdd({ ...student });
    student.name = '';
    setErrors(EMPTY_ERRORS);
  };

  return (
    <StudentRow
      disabled={disabled}
      errors={errors}
      id={id}
      name={name}
      onChange={handleChange}
      onSubmit={handleClick}
      student={student}
    >
      <Button
        disabled={disabled}
        onClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ alignSelf: 'center', justifySelf: 'stretch' }}
        variant="outlined"
      >
        Add
      </Button>
    </StudentRow>
  );
}
