import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const buildMutationFn = (courseId) => () =>
  axios.request({
    method: 'delete',
    url: `/api/courses/${courseId}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
  });

export const useCourseDeleteMutation = (
  courseId,
  { onError, onSuccess } = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: buildMutationFn(courseId),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
      queryClient.invalidateQueries({
        queryKey: ['course', { courseId: courseId.toString() }],
      });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, {
        autoHideDuration: 5000,
        variant: 'error',
      });
      onError(error.response.data);
    },
  });
};
