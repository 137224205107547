import { Button } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { useEffect, useMemo } from 'react';

export function RawDataButton({ assignmentId, data, id }) {
  const url = useMemo(() => {
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });

    return URL.createObjectURL(blob);
  }, [data]);

  useEffect(
    () => () => {
      URL.revokeObjectURL(url);
    },
    [url]
  );

  return (
    <Button
      component="a"
      download={`assignment-${assignmentId}-${id}.json`}
      href={url}
      size="small"
      startIcon={<DownloadIcon />}
      variant="outlined"
    >
      Get raw data
    </Button>
  );
}
