import { createContext, useState } from 'react';

const AssignmentStepContext = createContext('');

// eslint-disable-next-line no-unused-vars
const UpdateAssignmentStepContext = createContext((step) => {});

export function AssignmentStepContextProvider({ initialStep: initialComponent, children }) {
  const [currentStep, setCurrentStep] = useState(initialComponent);

  return (
    <UpdateAssignmentStepContext.Provider value={setCurrentStep}>
      <AssignmentStepContext.Provider value={currentStep}>
        {children}
      </AssignmentStepContext.Provider>
    </UpdateAssignmentStepContext.Provider>
  );
}
