import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

export const CLASSES_QUERY_KEY = 'classes';

// TODO: Double check if this convention follows official react-query tutorials
const getCourseList = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/courses`, { withCredentials: true });

  return response.data.courses;
};

export const useCoursesList = () =>
  useQuery({
    queryKey: ['classes'],
    queryFn: getCourseList,
    retry: false,
  });
