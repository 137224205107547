import { useMutation } from '@tanstack/react-query';

import { API_BASE_URL } from '../../config';

const useEdlinkIntegrationsSync = () =>
  useMutation(
    async () => {
      const response = await fetch(`${API_BASE_URL}/api/edlink/integrations-data-sync`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Sync failed');
      }

      return response.json();
    },
    {
      onSuccess: (data) => {
        console.log('Sync successful:', data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
      },
    }
  );

export default useEdlinkIntegrationsSync;
