import { Typography } from '@mui/material';
import { getWordCount } from '../../../../utils/text';

export function WordCounter({ text }) {
  return (
    <Typography sx={{ flexGrow: 1 }}>
      Word count: {getWordCount(text)}
    </Typography>
  );
}
