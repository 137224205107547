import { Box, Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { UserContext } from '../../../../../contexts/UserContext';

export function Stage4Complete({ assignmentTitle, assignmentText, generateImage }) {
  const user = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState();

  const generateAiImage = () => {
    axios
      .post(`${API_BASE_URL}/api/image`, { assignmentTitle, assignmentText, grade: 6 }, { withCredentials: true })
      .then((response) => {
        setImageUrl(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    // TODO: Add similar check to backend in order to prevent re-generation via url
    if (generateImage) {
      generateAiImage();
    }
  }, [generateImage]);

  const style = {
    backgroundColor: '#f5f5f5',
    paddingTop: 5,
    paddingBottom: 3,
  };

  return (
    <Paper style={style}>
      <Typography sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
        Good job!{' '}
        {generateImage ? (
          `But wait a second, PenGwen is cooking something for you..`
        ) : (
          <ThumbUpIcon style={{ fontSize: 100, color: 'green', marginLeft: '10px' }} />
        )}
      </Typography>
      {generateImage && (
        <Stack direction="column" gap={1} display="flex" justifyContent="center" alignItems="center" mb={2}>
          {imageUrl ? (
            <Box
              component="img"
              sx={{
                maxWidth: '512px',
                maxHeight: '512px',
                height: 'auto',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              src={imageUrl}
              alt="Your assignment in one picture"
            />
          ) : (
            <CircularProgress />
          )}
          {user.isAdmin && (
            <Button variant="contained" mt={2} color="primary" size="small" onClick={generateImage}>
              Regenerate
            </Button>
          )}
        </Stack>
      )}
    </Paper>
  );
}
