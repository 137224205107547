import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const getAssignments = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/assignments`, {
    withCredentials: true,
  });

  return response.data.assignments;
};

export const useAssignments = () =>
  useQuery({
    queryKey: ['assignments'],
    queryFn: getAssignments,
    retry: false,
  });
