import { useCallback, useEffect, useRef, useState } from 'react';
import { filter, last, map, pipe, propEq } from 'ramda';
import { Divider } from '@mui/material';

import { TIMELINE_RESOLUTION } from '../helpers';
import { usePlayerSteps } from './usePlayerSteps';
import { useUpdateGwenStep } from '../contexts/GwenStepContext';
import { OPS_TYPES } from '../../../../../helpers/constants';
import { PromptsHelp } from '../../../../../components/pen-gwen/PromptsHelp';
import useUser from '../../../../../hooks/useUser';

const getPromptResultHeader = (delta, isAdmin) => {
  let header = '';
  switch (delta.type) {
    case OPS_TYPES.WHAT_DO_YOU_THINK:
      header = 'WHAT DO YOU THINK';
      break;
    case OPS_TYPES.WRITE_STH_FOR_ME:
      header = 'WRITE STH FOR ME';
      break;
    case OPS_TYPES.HELP_ME_START:
      header = 'HELP ME START';
      break;
    case OPS_TYPES.CHECK_MY_WORK:
      header = 'CHECK MY WORK';
      break;
    default:
      header = '';
  }

  return isAdmin && delta?.prompt ? (
    <>
      <PromptsHelp prompt={delta.prompt} /> {header}
    </>
  ) : (
    header
  );
};

const filterDeltasByTime = (steps, startTime, endTime) =>
  steps.filter(({ time }) => time >= startTime && time < endTime);

const getTextDeltas = filter(propEq('type', 'text'));

const getPromptResultBubbleContent = (deltas, user) => pipe(
  filter((delta) =>
    [
      OPS_TYPES.HELP_ME_START,
      OPS_TYPES.CHECK_MY_WORK,
      OPS_TYPES.WRITE_STH_FOR_ME,
      OPS_TYPES.WHAT_DO_YOU_THINK,
    ].includes(delta.type)
  ), // TODO: OPS_TYPE VS DELTA.TYPE - unify naming
  map((delta) => (
    <>
      {getPromptResultHeader(delta, user.isAdmin)}
      <Divider sx={{ marginY: 1 }} />
      {delta.response}
    </>
  )),
  last
)(deltas);

const applyDeltas = (quillRef, steps) => steps.forEach(({ delta }) => quillRef.current.editor?.updateContents?.(delta));

const clearEditor = (quillRef) => quillRef.current.editor?.setContents?.([]);

const PLAYBACK_PAUSED = 'paused';
const toTimelineResolution = (value) => Math.ceil(value / TIMELINE_RESOLUTION);

export const usePlayerTimeline = ({ quillRef, speed = 1, steps }) => {
  const playbackTime = useRef(0);
  const lastTickTime = useRef(PLAYBACK_PAUSED);
  const reqRef = useRef(0);
  const user = useUser();

  const [isPaused, setPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const updateGwenResponse = useUpdateGwenStep(); // TODO: rename
  const timedSteps = usePlayerSteps(steps, TIMELINE_RESOLUTION);

  const finishTime = timedSteps.at(-1)?.time;

  const moveTimeline = useCallback(
    (timeDelta) => {
      let newTime = playbackTime.current + timeDelta;

      if (newTime > finishTime) {
        newTime = finishTime;
        setPaused(true);
      } else if (newTime < 0) {
        newTime = 0;
      }

      playbackTime.current = newTime;
      setCurrentTime(toTimelineResolution(newTime));
    },
    [finishTime]
  );

  const setPromptResultBubbleText = (applicableDeltas) => {
    const bubbleText = getPromptResultBubbleContent(applicableDeltas, user);
    if (bubbleText) {
      updateGwenResponse(bubbleText);
    }
  };

  useEffect(() => {
    if (isPaused) {
      lastTickTime.current = PLAYBACK_PAUSED;
      return;
    }

    const tick = (tickTime) => {
      if (lastTickTime.current === PLAYBACK_PAUSED) {
        lastTickTime.current = tickTime;
      }

      const tickTimeDelta = (tickTime - lastTickTime.current) * speed;
      const applicableDeltas = filterDeltasByTime(
        timedSteps,
        playbackTime.current,
        playbackTime.current + tickTimeDelta
      );

      applyDeltas(quillRef, getTextDeltas(applicableDeltas));
      setPromptResultBubbleText(applicableDeltas);
      moveTimeline(tickTimeDelta);

      lastTickTime.current = tickTime;
      reqRef.current = requestAnimationFrame(tick);
    };

    reqRef.current = requestAnimationFrame(tick);

    // eslint-disable-next-line consistent-return
    return () => {
      if (reqRef.current !== 0) {
        cancelAnimationFrame(reqRef.current);
      }
    };
  }, [isPaused, speed, timedSteps, moveTimeline]);

  const forward = useCallback(
    (time) => {
      const applicableDeltas = filterDeltasByTime(timedSteps, playbackTime.current, playbackTime.current + time);
      applyDeltas(quillRef, getTextDeltas(applicableDeltas));
      updateGwenResponse(getPromptResultBubbleContent(applicableDeltas));
      moveTimeline(time);
    },
    [timedSteps, moveTimeline]
  );

  const rewind = useCallback(
    (time) => {
      clearEditor(quillRef);

      const applicableDeltas = filterDeltasByTime(timedSteps, 0, playbackTime.current - time);
      applyDeltas(quillRef, getTextDeltas(applicableDeltas));
      updateGwenResponse(getPromptResultBubbleContent(applicableDeltas));
      moveTimeline(time * -1);
    },
    [timedSteps, moveTimeline]
  );

  const togglePlay = useCallback(() => {
    if (isPaused && playbackTime.current === finishTime) {
      rewind(playbackTime.current);
    }

    setPaused(!isPaused);
  }, [isPaused, finishTime, rewind]);

  const moveToStep = useCallback(
    (time) => {
      setCurrentTime(time);

      const newStepIdx = timedSteps
        .map((step) => toTimelineResolution(step.time))
        .findIndex((stepTime) => stepTime >= time);
      const newStep = timedSteps[newStepIdx];

      if (!newStep) {
        return;
      }

      if (newStep.time >= finishTime) {
        setPaused(true);
      }

      const isMovingBackwards = playbackTime.current > newStep.time;
      const applicableDeltas = isMovingBackwards
        ? filterDeltasByTime(timedSteps, 0, newStep.time)
        : filterDeltasByTime(timedSteps, playbackTime.current, newStep.time);

      if (isMovingBackwards) {
        clearEditor(quillRef);
        updateGwenResponse('');
      }

      applyDeltas(quillRef, getTextDeltas(applicableDeltas));
      setPromptResultBubbleText(applicableDeltas);

      playbackTime.current = newStep.time;
    },
    [timedSteps, finishTime]
  );

  return {
    currentTime,
    endTime: toTimelineResolution(finishTime),
    forward,
    isPaused,
    moveToStep,
    rewind,
    togglePlay,
  };
};
