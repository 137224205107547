import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { API_BASE_URL } from '../../../config';
import { parseDateWithoutTimezone } from '../../../utils/date';

const fetchStudent = async ({ queryKey }) => {
  const [, { courseId, studentId }] = queryKey;

  const response = await axios.get(
    `${API_BASE_URL}/api/courses/${courseId}/students/${studentId}`,
    { withCredentials: true }
  );

  return {
    ...response.data,
    assignments: response.data.assignments.map((assignment) => ({
      ...assignment,
      dueDate: assignment.dueDate
        ? parseDateWithoutTimezone(assignment.dueDate)
        : undefined,
    })),
  };
};

export const useCourseStudent = (courseId, studentId) =>
  useQuery({
    enabled: studentId !== null,
    queryKey: ['course-student', { courseId, studentId }],
    queryFn: fetchStudent,
    retry: false,
  });
