import React from 'react';
import { Paper, Grid } from '@mui/material';

export function AssignmentInstructions({ assignment }) {
  return (
    <Paper sx={{ mb: 5, padding: 2, backgroundColor: '#f0f6ff' }}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item container>
          <Grid item xs={12}>
            <strong>{assignment.title}</strong>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} sx={{ whiteSpace: 'pre-line', fontStyle: 'italic' }}>
            {assignment.instructions}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
