import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../config';

// TODO: Rename depending on the final step
const getWritingContextByUserAndAssignmentIDs = async ({ queryKey }) => {
  const [, { assignmentId, userId }] = queryKey;

  // TODO rename path to /api/writing-sessions/:userId/:assignmentId or /api/assignments/${assignmentId}/student/${userId} ?
  const response = await axios.get(`${API_BASE_URL}/api/students/${userId}/assignments/${assignmentId}`, {
    withCredentials: true,
  });

  // TODO: Verify what we use and really need
  return {
    assignment: response.data.assignment,
    userAssignment: response.data.userAssignment,
    steps: response.data.steps,
    student: response.data.student, // TODO: Where do we use it?
  };
};

export const useWritingContext = ({ assignmentId, userId }) =>
  useQuery({
    queryKey: ['writing-context', { assignmentId, userId }],
    queryFn: getWritingContextByUserAndAssignmentIDs,
    retry: false,
  });
