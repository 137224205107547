import { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import ReactGA from 'react-ga4';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { theme } from './theme';
import { RequiresLoginContextProvider } from './contexts/RequiresLoginContext';
import { UserContextProvider } from './contexts/UserContext';
import AppRoutes from './AppRoutes';

const TRACKING_ID = 'G-LCFX5TXSDK';
ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={5}>
          <ThemeProvider theme={theme}>
            <UserContextProvider>
              <RequiresLoginContextProvider>
                <AppRoutes />
              </RequiresLoginContextProvider>
            </UserContextProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
