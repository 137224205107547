import { Button, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../routes';
import { UserContext } from '../../contexts/UserContext';
import { EDLINK_AUTH_URL, LOGIN_STUDENT_URL, LOGIN_TEACHER_URL, COGNITO_LOGIN_URL } from '../../config';
import { GoogleLoginButton } from './GoogleLoginButton';
import { CodeLogin } from './CodeLogin';

// auth VS login url
const cognitoLogin = () => window.open(COGNITO_LOGIN_URL, '_self');
const edLinkLogin = () => window.open(EDLINK_AUTH_URL, '_self');
const loginAsTeacher = () => window.open(LOGIN_TEACHER_URL, '_self');
const loginAsStudent = () => window.open(LOGIN_STUDENT_URL, '_self');

const useMessages = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    switch (message) {
      case 'code-login-failed':
        enqueueSnackbar('Access code login failed. Please try again.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        navigate('/', { replace: true });
        break;
      default:
      // Do nothing
    }
  }, [message]);
};

export function HomePage() {
  const user = useContext(UserContext); // todo czy to nie powinien byc po prostu useUserContext ?
  useMessages();

  const commonPaperStyle = {
    padding: '20px',
    textAlign: 'center',
    height: '100%', // Ustawia wysokość na 100%
  };

  if (user) {
    if (user.isTeacher) {
      return <Navigate replace to={ROUTES.ASSIGNMENTS_INDEX} />;
    }

    if (user.isAdmin) {
      return <Navigate replace to={ROUTES.ADMIN_USERS} />;
    }

    return <Navigate replace to={ROUTES.STUDENT_DASHBOARD} />;
  }

  return (
    <Container>
      <Typography variant="h2" component="h1" color="text.main" textAlign="center" sx={{ margin: 4 }}>
        Sign In
      </Typography>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6}>
          <Paper elevation={3} style={{ ...commonPaperStyle }}>
            {/* <Typography sx={{ marginBottom: 2 }} variant="h5"> */}
            {/* TODO: add logo canvas/schoology/google classroom? */}
            {/* </Typography> */}
            <Button onClick={edLinkLogin} variant="contained">
              with Your School LMS Credentials
            </Button>
            <Divider light style={{ margin: '30px 0' }} />
            <CodeLogin />
            <Divider light style={{ margin: '30px 0' }} />
            <Stack spacing={2} direction="row" justifyContent="center" alignItems='center'>
            <Typography sx={{ marginBottom: 2 }} variant="h5">
              with Google
            </Typography>
              <GoogleLoginButton label="as Teacher" onClick={loginAsTeacher} />
              <GoogleLoginButton label="as Student" onClick={loginAsStudent} />
            </Stack>
            {/* <Divider light style={{ margin: '30px 0' }} /> */}
            {/* <Typography component="h2" sx={{ marginBottom: 2 }} variant="h5"></Typography> */}
            {/* <Button onClick={cognitoLogin} variant="contained">
              with Email and Password
            </Button> */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
