import { useState, createContext, useMemo } from 'react';

// TODO: double check if worth splitting into two files (context and provider)
export const TemplateContext = createContext();

// TODO: If I store the whole template, maybe I'd not have to use useTemplate hook in TemplatePreview?
export function TemplateProvider({ children, defaultSelectedId }) {
  const [selectedTemplateId, setSelectedTemplateId] = useState(defaultSelectedId);

  const contextValue = useMemo(
    () => ({ selectedTemplateId, setSelectedTemplateId }),
    [selectedTemplateId, setSelectedTemplateId]
  );

  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
}
