import { TableBody, TableHead } from '@mui/material';
import { Table, Td, Th, Tr } from '../../../components/layout/Table';

export function IntegrationsList({ integrations }) {
  return (
    <Table
      ariaLabel="List of available EdLink Integrations"
      caption="EdLink Integrations"
      noData={integrations.length === 0}
    >
      <TableHead>
        <Tr>
          <Th>Intehration ID</Th>
          <Th>Source Name</Th>
          <Th>Provider Name</Th>
          <Th>Team Name (type)</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {integrations.map((integration) => (
          <Tr key={integration.integrationId}>
            <Td>{integration.integrationId}</Td>
            <Td>{integration.sourceName}</Td>
            <Td>{integration.providerName}</Td>
            <Td>
              {integration.teamName} ({integration.teamType})
            </Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
