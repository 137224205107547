import { Box } from '@mui/material';

import { useSocketsStatus } from '../hooks/useSocketStatus';

export function SessionStatus() {
  const { connected } = useSocketsStatus();

  if (connected) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: '0 5px 5px 0',
        fontSize: '0.5em',
        color: 'red',
      }}
    >
      Not connected
    </Box>
  );
}
