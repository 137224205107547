import { useCallback } from 'react';

import { socket } from '../utils/sockets';

const DEFAULT_TIMEOUT = 4000;
const MAX_TIMEOUT = 16000;

const emit = (eventName, timeout, ...args) => {
  socket.timeout(timeout).emit(eventName, ...args, (err) => {
    if (err) {
      // Retry when no ACK
      emit(
        eventName,
        timeout * 2 <= MAX_TIMEOUT ? timeout * 2 : MAX_TIMEOUT,
        ...args
      );
    }
  });
};

export const useGuaranteedSocketsPush = (eventName) =>
  useCallback(
    (...args) => emit(eventName, DEFAULT_TIMEOUT, ...args),
    [eventName]
  );
