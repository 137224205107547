import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';

import { useEditorData } from '../api/useEditorData';
import { LoadingError } from '../../../../../components/LoadingError';
import { LoadingIndicator } from '../../../../../components/LoadingIndicator';
import { UserContext } from '../../../../../contexts/UserContext';
import { Player } from './Player';
import { RawDataButton } from './RawDataButton';

export function PlayerField({ assignmentId, id, size = 'small', userId, title }) {
  const { data, isError, isLoading, error } = useEditorData({ assignmentId, editor: id, userId });  // TODO: no neede to re-download the data
  const currentUser = useContext(UserContext);

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Grid item>
      <Typography>{title}</Typography>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Player size={size} steps={data}>
          {currentUser.isAdmin && <RawDataButton assignmentId={assignmentId} id={id} data={data} />}
        </Player>
      )}
    </Grid>
  );
}
