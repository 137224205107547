import { Paper } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useRequireLogin } from '../contexts/RequiresLoginContext';

export function LoadingError({ error }) {
  const requireLogin = useRequireLogin();

  // INFO: It fixes the following scenario:
  // if (isErrorAssignment || isErrorCourses || isErrorAsignees) {
  //   return <LoadingError error={errorAssignment || errorCourses || errorAssignees} />;
  // }
  // TODO: Dive deeper

  if (error?.response?.status === 401) {
    requireLogin();
    return null;
  }

  if (error.response?.data?.googleSync) {
    // TODO: There's sth wrong here, when I try to edit a class, I get:
    // warning: cannot update a compoent (`%s`) while rendering a different component (`%s`). To lcate the bad setState() call inside `%s`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    requireLogin();
    return null;
  }

  // if (error?.response?.status === 403) {
  //   return (
  //     <Paper sx={{ padding: 2 }}>
  //       <h1>Forbidden</h1>
  //       <p>You don't have permission to access this resource</p>
  //     </Paper>
  //   );
  // }

  return (
    <Paper sx={{ padding: 2 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ErrorIcon sx={{ marginRight: 1, fontSize: '30px', color: 'red' }} />
        <h1 style={{ margin: 0 }}>Error</h1>
      </div>
      <p>{error?.message}</p>
    </Paper>
  );
}
