import { Box } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';

export function BooleanValue({ value, showLabel = false }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {value ? (
        <>
          <CheckIcon style={{ color: 'green' }} /> {showLabel && 'Yes'}
        </>
      ) : (
        <>
          <CloseIcon style={{ color: 'red' }} /> {showLabel && 'No'}
        </>
      )}
    </Box>
  );
}
