import { Button } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';

export default function DebugPage() {
  const user = useContext(UserContext);

  // eslint-disable-next-line no-console
  console.log('process.env', process.env);

  return (
    <div>
      {user && user.isAdmin && (
        <Button
          onClick={() => {
            throw new Error('Sentry Test Frontend Error');
          }}
        >
          Test Sentry
        </Button>
      )}
    </div>
  );
}
