export const getWordCount = (str) => (!str || str.trim().length === 0 ? 0 : str.trim().split(/\s+/).length);

export const removeWhitespaces = (str) => {
  if (str === undefined) {
    return '';
  }
  return str.replace(/\s/g, ''); // space, tab, new line, carriage return
};

export const removeNewLine = (str) => {
  if (str === undefined) {
    return '';
  }
  return str.replace(/\n/g, '');
};

export const shorten = (string, length) => (string.length > length ? `${string.substring(0, length)}...` : string);
