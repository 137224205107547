import PropTypes from 'prop-types';

import './bubble.css';

export function Bubble({ children }) {
  return <div className="bubble bubble-bottom-right">{children}</div>;
}

Bubble.propTypes = {
  children: PropTypes.node.isRequired,
};
