import { useContext, useMemo, useState } from 'react';
import { Box, Checkbox, TableBody, TableHead, TableSortLabel, Typography } from '@mui/material';
import { ContentCopy, Edit as EditIcon, Warning as WarningIcon } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../../../routes';
import { UserContext } from '../../../../contexts/UserContext';
import { LoadingError } from '../../../../components/LoadingError';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useUserMutation } from '../api/useUserMutation';
import { useUsers } from '../api/useUsers';
import { DeleteUserButton } from './DeleteUserButton';
import { IconButtonWithTooltip } from '../../../../components/layout/IconButtonWithTooltip';
import { Table, Td, Tr, Th } from '../../../../components/layout/Table';
import { copyToClipboard } from '../../../../utils/clipboard';

export function UsersTable() {
  const { data: users, isLoading, isError, error } = useUsers();
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');

  const mutation = useUserMutation({
    onSuccess: () => {
      enqueueSnackbar('User permissions updated', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to update permissions', { variant: 'error' });
    },
  });
  const currentUser = useContext(UserContext);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = useMemo(() => {
    if (!users) {
      return [];
    }

    return [...users].sort((a, b) => {
      if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
        return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
      }

      if (orderBy === 'createdAt') {
        const aDate = Date.parse(a[orderBy]);
        const bDate = Date.parse(b[orderBy]);
        return order === 'asc' ? aDate - bDate : bDate - aDate;
      }

      let aValue = a[orderBy] ? a[orderBy].toLowerCase() : '';
      let bValue = b[orderBy] ? b[orderBy].toLowerCase() : '';

      if (orderBy === 'registration') {
        aValue = a.externalSource ? a.externalSource : 'app';
        bValue = b.externalSource ? a.externalSource : 'app';
      }

      return (order === 'asc' ? aValue < bValue : aValue > bValue) ? -1 : 1;
    });
  }, [users, orderBy, order]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <LoadingError error={error} />;
  }

  // TODO: Warning: validateDOMNesting(...): <tr> cannot appear as a child of <table>. Add a <tbody>, <thead> or <tfoot> to your code to match the DOM tree generated by the browser.
  return (
    <Table ariaLabel="List of registered users" caption="Users" pagination defaultRowsPerPage={25}>
      <TableHead>
        <Tr>
          <Th>
            <TableSortLabel active={orderBy === 'id'} direction={order} onClick={() => handleRequestSort('id')}>
              ID
            </TableSortLabel>
          </Th>
          <Th>
            <TableSortLabel active={orderBy === 'name'} direction={order} onClick={() => handleRequestSort('name')}>
              Name
            </TableSortLabel>
          </Th>
          <Th>
            <TableSortLabel
              active={orderBy === 'registration'}
              direction={order}
              onClick={() => handleRequestSort('registration')}
            >
              Registered Via
            </TableSortLabel>
          </Th>
          <Th>Teacher</Th>
          <Th>Admin</Th>
          <Th>Early access</Th>
          <Th>
            <TableSortLabel
              active={orderBy === 'createdAt'}
              direction={order}
              onClick={() => handleRequestSort('createdAt')}
            >
              Registered At
            </TableSortLabel>
          </Th>
          <Th>Actions</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {sortedUsers.map((appUser) => (
          <Tr key={appUser.id} style={{ backgroundColor: currentUser.id === appUser.id ? 'lightgreen' : 'inherit' }}>
            <Td>{appUser.id}</Td>
            <Td>
              {appUser.status !== 'error' ? (
                appUser.name
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <WarningIcon color="error" sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                  <Typography
                    component="small"
                    color="text.secondary"
                    fontSize="small"
                    fontStyle="italic"
                    display="block"
                  >
                    This user has configuration issues
                  </Typography>
                </Box>
              )}
            </Td>
            <Td>
              {appUser.externalSource ? (
                <>
                  {appUser.externalSource}
                  <IconButtonWithTooltip
                    key={appUser.externalId}
                    tooltip={appUser.externalId}
                    onClick={() => copyToClipboard(appUser.externalId)}
                    icon={<ContentCopy />}
                  />
                </>
              ) : (
                'app'
              )}
            </Td>
            <Td>
              <Checkbox
                size="small"
                inputProps={{ 'aria-label': `Is ${appUser.name} a teacher?` }}
                checked={Boolean(appUser.isTeacher)}
                disabled={mutation.isLoading}
                onChange={(e) => mutation.mutate({ data: { isTeacher: e.target.checked }, userId: appUser.id })}
              />
            </Td>
            <Td>
              <Checkbox
                size="small"
                inputProps={{ 'aria-label': `Is ${appUser.name} an admin?` }}
                checked={Boolean(appUser.isAdmin)}
                disabled={appUser.id === currentUser.id || mutation.isLoading}
                onChange={(e) => mutation.mutate({ data: { isAdmin: e.target.checked }, userId: appUser.id })}
              />
            </Td>
            <Td>
              <Checkbox
                size="small"
                inputProps={{ 'aria-label': `Does ${appUser.name} have early accesss to new features?` }}
                checked={Boolean(appUser.earlyAccess)}
                disabled={mutation.isLoading}
                onChange={(e) => mutation.mutate({ data: { earlyAccess: e.target.checked }, userId: appUser.id })}
              />
            </Td>
            <Td style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
              {new Date(appUser.createdAt).toLocaleString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Td>
            <Td>
              <IconButtonWithTooltip
                icon={<EditIcon />}
                tooltip="Edit"
                disabled={mutation.isLoading}
                to={ROUTES.editUser(appUser.id)}
              />
              <DeleteUserButton disabled={appUser.id === currentUser.id || mutation.isLoading} userId={appUser.id} />
            </Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
