import { Upload as UploadIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import { isEmpty, pick } from 'ramda';

const mapKeysToLowerCase = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

const getStudentData = (dataObj) => pick(['name'], mapKeysToLowerCase(dataObj));
const isStudentValid = (student) => !isEmpty(student) && !isEmpty(student.name);

export function CsvImportButton({ onItemAdded }) {
  const [open, setOpen] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [filename, setFilename] = useState('');
  const inputRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setProcessing(true);
    // eslint-disable-next-line import/no-extraneous-dependencies
    import('papaparse').then(async (Papa) => {
      const formData = new FormData(e.target);
      const values = Object.fromEntries(formData);
      Papa.parse(await values.csv.text(), {
        complete: () => {
          setProcessing(false);
          setOpen(false);
        },
        header: true,
        step: (row) => {
          const student = getStudentData(row.data);
          if (isStudentValid(student)) {
            onItemAdded(student);
          }
        },
      });
    });
  };

  const handleSelectFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileSelected = (e) => {
    if (e.target.files.length === 1) {
      setFilename(e.target.files[0].name);
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} size="small" startIcon={<UploadIcon />} variant="outlined">
        Import from CSV
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="import-students-dialog-title"
        aria-describedby="import-students-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="import-students-dialog-title">Import students from CSV</DialogTitle>
          <DialogContent>
            <DialogContentText id="import-students-dialog-description">
              You can import students for your class from a CSV file. Please ensure that the file follows the format
              below, with &apos;name&apos; as a mandatory column header:
            </DialogContentText>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {`
                name
                Mike
                Emily
              `}
            </Typography>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <input
                accept="text/csv"
                name="csv"
                onChange={handleFileSelected}
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={handleSelectFile} variant="text">
                        Browse
                      </Button>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Select a CSV file…"
                value={filename}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={isProcessing} onClick={() => setOpen(false)} variant="text">
              Cancel
            </Button>
            <Button autoFocus disabled={isProcessing} variant="contained" type="submit">
              {isProcessing ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Import students'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
