import { TableBody, TableHead } from '@mui/material';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { ROUTES } from '../../../routes';
import { ButtonWithTooltip } from '../../../components/layout/ButtonWithTooltip';
import { Table, Td, Th, Tr } from '../../../components/layout/Table';
import { useStudentsAssignments } from './students/[id]/useStudentsAssignments';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { LoadingError } from '../../../components/LoadingError';
import { parseDateWithoutTimezone } from '../../../utils/date';
import { BooleanValue } from '../../../components/BooleanValue';
import { isOutlineAndWritingAssignment } from '../../../helpers/assignment';

export function AssignmentsAssignedToStudents({ studentIds }) {
  const {
    data: assignments,
    isLoading,
    isError,
    error,
  } = studentIds.length > 0
    ? useStudentsAssignments(studentIds)
    : { data: [], isLoading: false, isError: false, error: null };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Table caption="Assignments designated for students in this class" noData={assignments.length === 0}>
      <TableHead>
        <Tr>
          <Th width="30px">#</Th>
          <Th align="left">Title</Th>
          <Th align="left">Template</Th>
          <Th>Outline</Th>
          <Th>Gwen AI</Th>
          <Th>Due date</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {assignments.map((assignment, index) => (
          <Tr key={assignment.id}>
            <Td>{index + 1}</Td>
            <Td align="left">
              <ButtonWithTooltip
                label={assignment.title}
                tooltip="View Assignment"
                icon={<AssignmentIcon />}
                to={ROUTES.showAssignment(assignment.id)}
              />
            </Td>
            <Td align="left">{assignment?.template?.name}</Td>
            <Td>
              <BooleanValue value={isOutlineAndWritingAssignment(assignment.type)} />
            </Td>
            <Td>
              <BooleanValue value={assignment.useGwen} />
            </Td>
            <Td>{assignment.dueDate ? format(parseDateWithoutTimezone(assignment.dueDate), 'MMM do, yyyy') : '-'}</Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
