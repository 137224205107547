import { useEffect, useState } from 'react';
import { Typography, Stack } from '@mui/material';
import { getWordCount, removeWhitespaces } from '../../../utils/text';

const externalPastePercentageLimit = 20

// TODO: Add class/service that represents json in db + methods that calculate data
export function WritingStats({ text, externalTextPasteEvents, onStatsChange, writingStats }) {
  const textCharsCount = removeWhitespaces(text).length;
  const [externalTextPasteAttemptsCount, setExternalTextPasteAttemptsCount] = useState(0);
  const [externalTextPastedCharsCount, setExternalTextPastedCharsCount] = useState(0);
  const externalTextPasteCharsCountFromEvents = externalTextPasteEvents?.reduce(
    (acc, pasteEvent) => acc + pasteEvent.length,
    0
  );
  const getPercentage = (pastedChars, totalChars) => {
    if (typeof pastedChars === 'number' && typeof totalChars === 'number' && totalChars !== 0) {
      return Math.round((pastedChars / totalChars) * 100);
    }

    return 0;
  };
  const externalTextPastePercentage = getPercentage(externalTextPastedCharsCount, textCharsCount);
  const percentageColor = (percentage) => (percentage > externalPastePercentageLimit ? 'red' : 'black');
  // const externalOutlinePastedCharsCount = 0; // TODO: We don't analyze outline yet and plan to be build once the new template system gets implemented

  useEffect(() => {
    setExternalTextPasteAttemptsCount(writingStats?.externalTextPasteAttempts || externalTextPasteEvents?.length || 0);
    setExternalTextPastedCharsCount(
      writingStats?.externalTextPastedCharsCount || externalTextPasteCharsCountFromEvents || 0
    );
  }, [externalTextPasteEvents, writingStats]);

  useEffect(() => {
    if (onStatsChange) {
      onStatsChange({
        textCharsCount,
        externalTextPastePercentage,
        externalTextPastedCharsCount,
        externalTextPasteAttempts: externalTextPasteEvents.length,
        // externalOutlinePastedCharsCount,
      });
    }
  }, [externalTextPasteEvents, text]);

  return (
    <Stack>
      <Typography>Word count: {getWordCount(text)}</Typography>
      <Typography>Character count: {textCharsCount}</Typography>
      <Typography>External Copy Paste Attempts: {externalTextPasteAttemptsCount}</Typography>
      <Typography>
        Characters Copied Externally: {externalTextPastedCharsCount} (
        <span
          style={{ color: percentageColor(externalTextPastePercentage) }}
        >{`${externalTextPastePercentage}% `}</span>
        of total characters submitted)
      </Typography>
    </Stack>
  );
}
