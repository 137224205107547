import { Button, CircularProgress } from '@mui/material';

export function PenGwenButton({ children, disabled, loading, ...props }) {
  return (
    <Button
      color="primary"
      disabled={disabled || loading}
      variant="contained"
      {...props}
    >
      {loading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : children}
    </Button>
  );
}
