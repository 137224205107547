import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';

const getStudentsAssignments = async ({ queryKey }) => {
  const [, { studentIds }] = queryKey;

  const response = await axios.get(`${API_BASE_URL}/api/assignments/by-users/${studentIds.join(',')}`, {
    withCredentials: true,
  });

  return response.data.assignments;
};

export const useStudentsAssignments = (studentIds) =>
  useQuery({
    queryKey: ['assignments', { studentIds }],
    queryFn: getStudentsAssignments,
    retry: false,
  });
