import { Grid, Paper, Typography } from '@mui/material';
import { AssignmentStepContextProvider } from '../../../../../contexts/AssignmentStepContext';
import { useGwenStep } from '../contexts/GwenStepContext';
import { PenGwen } from '../../../../../components/pen-gwen/PenGwen';
import { PlayerField } from './PlayerField';
import { createOutlineTree } from '../../../../../helpers/template';
import { STEPS } from '../../../../../helpers/constants';

export function StepsAnalytics({ assignment, student, templateComponents, useOutline }) {
  const promptResult = useGwenStep();
  const templateComponentsSteps = createOutlineTree(templateComponents);

  return (
    <AssignmentStepContextProvider initialStep={STEPS.WRITING_TEXT}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={assignment.useGwen ? 9 : 12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Writing Assignment
            </Typography>
            <PlayerField assignmentId={assignment.id} userId={student.id} id={STEPS.WRITING_TEXT} />
          </Paper>
          {useOutline && (
            <Paper sx={{ padding: 2, mt: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                The outline
              </Typography>
              <Grid container direction="column" spacing={3}>
                {templateComponentsSteps
                  .flatMap((section) => section.components)
                  .map((component) => (
                    <PlayerField
                      key={component.id}
                      id={component.id}
                      title={
                        <>
                          <strong>{component.name}</strong>
                          {component.shortDescription && `: ${component.shortDescription}`}
                        </>
                      }
                      assignmentId={assignment.id}
                      userId={student.id}
                    />
                  ))}
              </Grid>
            </Paper>
          )}
        </Grid>
        {assignment.useGwen && (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ position: 'relative', display: 'flex', alignItems: 'end', justifyContent: 'center' }}
          >
            <PenGwen
              promptResult={promptResult}
              sx={{ position: 'sticky', top: '0', bottom: '0', overflow: 'hidden', marginY: -2 }}
            />
          </Grid>
        )}
      </Grid>
    </AssignmentStepContextProvider>
  );
}
