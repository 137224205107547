import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';
import { useRequireLogin } from '../../../contexts/RequiresLoginContext';

const buildUpdateQuery =
  (assignmentId) =>
  ({ assignment }) =>
    axios.request({
      // TODO - I think I'd like to migrate to PATCH or handle both methods
      // W RESTful API, metody HTTP PUT i PATCH są używane do aktualizacji zasobów, ale mają nieco różne zastosowania:
      // PUT jest używane do pełnej aktualizacji zasobu. Oznacza to, że musisz przesłać cały zasób z nowymi danymi. Jeśli jakieś pola nie są przesłane, są one ustawiane na domyślne wartości lub usuwane. PUT jest idempotentne, co oznacza, że wielokrotne wykonanie tego samego żądania PUT daje ten sam wynik.
      // PATCH jest używane do częściowej aktualizacji zasobu. Oznacza to, że przesyłasz tylko te pola, które chcesz zaktualizować. Pola, których nie przesłano, pozostają niezmienione. PATCH nie jest zawsze idempotentne, ponieważ wynik może zależeć od aktualnego stanu zasobu.
      method: assignmentId ? 'put' : 'post',
      url: `/api/assignments/${assignmentId || ''}`,
      baseURL: API_BASE_URL,
      withCredentials: true,
      data: assignment,
    });

export const useAssignmentMutation = (assignmentId, { onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();
  const requireLogin = useRequireLogin();

  return useMutation({
    mutationFn: buildUpdateQuery(assignmentId),
    onSuccess: (response) => {
      if (assignmentId) {
        queryClient.invalidateQueries({
          queryKey: ['assignment', { assignmentId: assignmentId.toString() }],
        });
      }
      onSuccess?.(response.data);
    },
    onError: (error) => {
      if (error.response.data.googleSync) {
        requireLogin();
      } else {
        enqueueSnackbar(error.response.data.error, {
          autoHideDuration: 5000,
          variant: 'error',
        });
      }
      onError(error.response.data);
    },
  });
};
