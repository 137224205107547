import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { getComponentById } from '../helpers/template';
import { removeNewLine } from '../utils/text';

const CurrentFieldContext = createContext('');
const FieldContextContext = createContext(undefined);

// eslint-disable-next-line no-unused-vars
const UpdateFieldContext = createContext((step) => {});

// TODO: Rename to reflect usage
export function GwenFieldContextProvider({ children, outlineSteps, templateComponents }) {
  const [currentComponentId, setCurrentComponentId] = useState('');
  const [fieldContext, setFieldContext] = useState(undefined);
  // console.log('current fieldContext', fieldContext);
  const visitedFields = useRef({}).current; // TODO: how does it work? Do we really need it?
  // console.log('visitedFields', visitedFields);  //hash with id and true values
  const updateCurrentField = useCallback((componentId) => {
    setCurrentComponentId(componentId);

    const currentComponent = getComponentById(templateComponents, componentId);
    // INFO: If we remove text from a text editor, we still get '\n' in the value
    const valueWithoutWhiteChars = removeNewLine(outlineSteps[componentId]);
    if (visitedFields[componentId]) {
      setFieldContext((context) => {
        if (context?.id === componentId) {
          return context;
        }

        return {
          id: componentId,
          componentName: currentComponent.name,
          componentValue: valueWithoutWhiteChars,
          componentDesc: currentComponent.shortDescription,
          sectionName: currentComponent.parent.name,
        };
      });
    } else {
      setFieldContext({
        id: componentId,
        componentName: currentComponent.name,
        componentValue: valueWithoutWhiteChars,
        componentDesc: currentComponent.shortDescription,
        sectionName: currentComponent.parent.name,
      });
      visitedFields[componentId] = true;
    }
  }, []);

  return (
    <UpdateFieldContext.Provider value={updateCurrentField}>
      <CurrentFieldContext.Provider value={currentComponentId}>
        <FieldContextContext.Provider value={fieldContext}>{children}</FieldContextContext.Provider>
      </CurrentFieldContext.Provider>
    </UpdateFieldContext.Provider>
  );
}

export const useCurrentField = () => useContext(CurrentFieldContext);
export const useFieldContext = () => useContext(FieldContextContext);
export const useUpdateCurrentField = () => useContext(UpdateFieldContext);
