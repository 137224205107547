import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../../config';

const getUser = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  const response = await axios.get(
    `${API_BASE_URL}/api/admin/users/${userId}`,
    {
      withCredentials: true,
    }
  );

  return response.data.user;
};

export const useUser = (userId) =>
  useQuery({
    queryKey: ['user', { userId }],
    queryFn: getUser,
    retry: false,
  });
