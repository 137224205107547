import { Container } from '@mui/material';
import { ROUTES } from '../../../routes';
import { UsersTable } from './components/UsersTable';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

export function AdminUsersPage() {
  return (
    <Container>
      <Breadcrumb addPath={ROUTES.ADMIN_USERS_NEW} addTooltip="Add New User" />
      <UsersTable />
    </Container>
  );
}
