import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../../config';

const getDefaultPromptsConfiguration = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/admin/prompts-configurations/1`, { withCredentials: true });

  return response.data.promptsConfiguration;
};

export const useDefaultPromptsConfiguration = () =>
  useQuery({
    queryKey: ['defaultPromptsConfiguration'],
    queryFn: getDefaultPromptsConfiguration,
    retry: false,
  });
