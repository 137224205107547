import { useContext } from 'react';

import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { PenGwen } from './PenGwen';
import { PenGwenButton } from './PenGwenButton';
import { PromptsHelp } from './PromptsHelp';
import { UserContext } from '../../contexts/UserContext';
import { useCurrentField, useFieldContext } from '../../contexts/GwenFieldContext'; // TODO: rename?
import { useCheckMyWork } from '../../hooks/ai/useCheckMyWork';
import { useStepsData } from '../../contexts/StepsDataContext';
import { removeNewLine } from '../../utils/text';
import { getOutlineText } from '../../helpers/template';
import { OPS_TYPES } from '../../helpers/constants';
import { API_BASE_URL } from '../../config';

const DEFAULT_MESSAGE = `Hey there! I'm here to help you write your assignment!`;
const ERROR_MESSAGE = `We had a problem with getting a help for you, sorry. Please try again.`;

export function PenGwenOutline({
  assignmentId,
  promptResultAvailable,
  grade,
  templeteOutlineTree,
  outlineStructure,
  templateName,
  threadId,
}) {
  const user = useContext(UserContext);
  const currentComponentId = useCurrentField();
  const fieldContext = useFieldContext();
  // console.log('componentId', currentComponentId);
  // console.log('fieldContext', fieldContext); // It does not pass updated text values (I broke it?) so I use stepsData
  const outlineSteps = useStepsData();
  const currentComponentValue = removeNewLine(outlineSteps[currentComponentId]);
  const outlineText = getOutlineText(templeteOutlineTree, outlineSteps);
  const noUserInput = currentComponentValue.length === 0;
  const {
    data,
    isError,
    isLoading,
    callEndpoint: getAiHelp, // TODO: double-check!
    // TODO: Create separate hook useHelpMeStart
  } = useCheckMyWork({
    assignmentId,
    outlineItemContext: { ...fieldContext, componentValue: currentComponentValue }, // override with updated value
    grade,
    outlineStructure,
    outlineText,
    templateName,
    type: noUserInput ? OPS_TYPES.HELP_ME_START : OPS_TYPES.CHECK_MY_WORK,
  });

  const buttonLabel = noUserInput ? 'Help Me Start' : 'Check My Work';
  const hasPromptResultForCurrentField = fieldContext?.id === data?.outlineItemContext?.id; // TODO: double check if this still works
  const bubleText = isError
    ? ERROR_MESSAGE
    : (hasPromptResultForCurrentField && data?.promptResult) || fieldContext?.desc || DEFAULT_MESSAGE;

  return (
    <PenGwen
      promptResult={bubleText}
      textToSpeech
      sx={{ position: 'sticky', top: '0', bottom: '16px', overflow: 'hidden' }}
    >
      {/* <PenGwenButton disabled={!promptResultAvailable || !fieldContext.id} loading={isLoading} onClick={requestAiResponse}>  TODO: was it about limiting Gwen usage? */}
      <Stack direction="column" alignItems="center" gap={0.5}>
        <PenGwenButton disabled={!promptResultAvailable} loading={isLoading} onClick={getAiHelp}>
          {buttonLabel}
        </PenGwenButton>
        {user.isAdmin && (
          <>
            {data?.prompt && hasPromptResultForCurrentField && <PromptsHelp prompt={data?.prompt} />}
            {threadId && (
              <Button size='small' component={Link} to={`${API_BASE_URL}/api/thread/${threadId}.html`} target="_blank">
                Thread
              </Button>
            )}
          </>
        )}
      </Stack>
    </PenGwen>
  );
}
