import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';

export function DeleteTemplateButton({ deleteHandler }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Duplicate">
        <IconButton color="error" onClick={() => setOpen(true)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="delete-template-dialog-title"
        aria-describedby="delete-template-dialog-description"
      >
        <DialogTitle id="delete-template-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-template-dialog-description">
            Deleting template cannot be undone. Are you sure you want to delete this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="text">
            Cancel
          </Button>
          <Button autoFocus color="error" onClick={deleteHandler} variant="contained">
            Delete template
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
