import { Button, CircularProgress, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { usePromptsConfigurationUpdateMutation } from './api/usePromptsConfigurationUpdateMutation';
import { VariablesInfo } from './VariablesInfo';

export function OutlinePrompts({ promptsConfiguration }) {
  const { register, handleSubmit } = useForm();
  const mutation = usePromptsConfigurationUpdateMutation();
  const saving = mutation.isLoading;

  const onSubmit = (data) => {
    mutation.mutate({ data, promptsConfigurationId: promptsConfiguration.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VariablesInfo showOutlineVariables />
      <TextField
        label="Outline Assistant Instructions"
        helperText="This overrides the default assistant instructions set via openAI dasboard. We can think of it as a system message."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.outlineAssistantInstructions}
        fullWidth
        sx={{ my: 2 }}
        {...register(`outlineAssistantInstructions`)}
      />
      <TextField
        label="'Check My Work' Prompt"
        helperText="Used when selected outline item is not empty."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.checkMyWorkPrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`checkMyWorkPrompt`)}
      />
      <TextField
        label={`'Help Me Start' Prompt`}
        helperText="Used when there's no text in the selected outline item."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.helpMeStartPrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`helpMeStartPrompt`)}
      />

      <Button variant="contained" type="submit" disabled={saving}>
        {saving && <CircularProgress sx={{ marginRight: 2 }} size={20} />}
        Save
      </Button>
    </form>
  );
}
