import { useMemo, useState } from 'react';
import { ascend, descend, prop, sortWith } from 'ramda';

export const useSorting = ({
  defaultField,
  defaultOrder,
  fallbackField,
  onSort,
}) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultField);

  const handlers = useMemo(() => {
    const createSortHandler = (property) => () => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      onSort?.(property, isAsc ? 'desc' : 'asc');
    };

    const comparator = order === 'asc' ? ascend : descend;
    const sortItems = sortWith([
      comparator(prop(orderBy)),
      ascend(prop(fallbackField)),
    ]);

    return {
      createSortHandler,
      sortItems,
    };
  }, [orderBy, order]);

  return {
    order,
    orderBy,
    ...handlers,
  };
};
