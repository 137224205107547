import { Grid } from '@mui/material';
import { AssignmentStepContextProvider } from '../../../../../contexts/AssignmentStepContext';
import { PenGwenOutline } from '../../../../../components/pen-gwen/PenGwenOutline';
import { GwenFieldContextProvider } from '../../../../../contexts/GwenFieldContext';
import { OutlineTemplateItems } from './OutlineItems';

export function Stage1Outline({
  assignment,
  outlineSteps,
  userAssignment,
  templateComponents,
  grade,
  templeteOutlineTree,
  outlineStructure,
  templateName,
}) {
  const getFirstComponent = () => templateComponents.find((component) => component.parentId !== null);
  const initialComponent = getFirstComponent(templateComponents).id;

  return (
    <AssignmentStepContextProvider initialComponent={initialComponent}>
      {/* TODO: Relocate context higher so I could use it in Outline? */}
      {/* TODO: Rename */}
      <GwenFieldContextProvider outlineSteps={outlineSteps} templateComponents={templateComponents}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={assignment.useGwen ? 9 : 12}>
            <OutlineTemplateItems userAssignmentId={userAssignment.id} templateComponents={templateComponents} />
          </Grid>
          {assignment.useGwen && (
            <Grid item xs={12} sm={3} sx={{ position: 'relative', display: 'flex', alignItems: 'end' }}>
              <PenGwenOutline
                assignmentId={assignment.id}
                promptResultAvailable
                grade={grade}
                templeteOutlineTree={templeteOutlineTree}
                outlineStructure={outlineStructure}
                templateName={templateName}
                threadId={userAssignment.outlineThreadId}
              />
            </Grid>
          )}
        </Grid>
      </GwenFieldContextProvider>
    </AssignmentStepContextProvider>
  );
}
