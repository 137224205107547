export const STEPS = {
  OUTLINE_HELP_ME_START: 'OUTLINE_HELP_ME_START',
  OUTLINE_CHECK_MY_WORK: 'OUTLINE_CHECK_MY_WORK',
  WRITING_TEXT: 'WRITING_TEXT',
  WRITING_WHAT_DO_YOU_THINK: 'WRITING_WHAT_DO_YOU_THINK',
  WRITING_WRITE_STH_FOR_ME: 'WRITING_WRITE_STH_FOR_ME',
  ASSIGNMENT_POST_ANALYSIS: 'ASSIGNMENT_POST_ANALYSIS',
};

export const OPS_TYPES = {
  HELP_ME_START: 'help_me_start',
  CHECK_MY_WORK: 'check_my_work',
  WHAT_DO_YOU_THINK: 'what_do_you_think',
  WRITE_STH_FOR_ME: 'write_sth_for_me',
};
