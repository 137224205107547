import { Box, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { removeWhitespaces } from '../../../utils/text';

export function CompletedOutlinePreview({ templeteOutlineTree, outlineSteps }) {
  // OutlineStepsHash/Config ?
  const style = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    paddingTop: 3,
    paddingBottom: 3,
    marginTop: 50,
  };

  return (
    <Paper style={style}>
      <Box sx={{ padding: 1 }}>
        {templeteOutlineTree.map((section) => (
          <List key={section.id} dense sx={{ padding: 0, margin: 0 }}>
            <ListItem sx={{ paddingY: 0, paddingLeft: 0 }}>
              <ListItemText
                style={{ margin: 0 }}
                primary={
                  <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                    {section.name}
                  </Typography>
                }
              />
            </ListItem>
            {section.components.map((component) => {
              const isFieldEmpty = !removeWhitespaces(outlineSteps[component.id]);
              const color = isFieldEmpty ? 'red' : '';
              return (
                <List key={component.id} dense sx={{ paddingY: 0, ml: 0.5 }}>
                  <ListItem sx={{ padding: 0 }}>
                    <ListItemText
                      style={{ margin: 0, color }}
                      primary={
                        <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                          {component.name}
                        </Typography>
                      }
                      secondary={
                        <Typography fontSize="small" sx={{ color, pl: 0.5 }}>
                          {isFieldEmpty ? 'Skipped' : outlineSteps[component.id]}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              );
            })}
          </List>
        ))}
      </Box>
    </Paper>
  );
}
