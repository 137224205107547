import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

export function CompletionApiConfig({ register, promptsConfiguration }) {
  return (
    <Box>
      <Typography variant="h5">Completion API:</Typography>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id="completion-model-label">Model</InputLabel>
        <Select
          labelId="completion-model-label"
          id="completion-model"
          defaultValue={promptsConfiguration.completionModel}
          label="Model"
          size="small"
          {...register(`completionModel`)}
        >
          <MenuItem value="gpt-4">GPT-4</MenuItem>
          <MenuItem value="gpt-4o">GPT-4o</MenuItem>
          <MenuItem value="gpt-4o-mini">GPT-4o mini</MenuItem>
          <MenuItem value="gpt-4-32k">GPT-4 32k</MenuItem>
          <MenuItem value="gpt-3.5-turbo">GPT-3.5</MenuItem>
          <MenuItem value="gpt-3.5-turbo-16k">GPT-3.5 16k</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Maximum length"
        helperText="max_tokens setting"
        defaultValue={promptsConfiguration.completionMaxTokens}
        size="small"
        fullWidth
        sx={{ my: 2 }}
        type="number"
        inputProps={{ min: 100, max: 8192, step: 1 }}
        {...register(`completionMaxTokens`, { min: 100, max: 8192 })}
      />
      <TextField
        label="Temperature"
        helperText="temperature setting - adjust this or Top Probability, not both"
        defaultValue={promptsConfiguration.completionTemperature}
        size="small"
        fullWidth
        sx={{ my: 2 }}
        type="number"
        inputProps={{ min: 0, max: 2, step: 0.1 }}
        {...register(`completionTemperature`, { min: 0, max: 2 })}
      />
      <TextField
        label="Top Probability"
        helperText="top_p setting - used to choose tokens with this probability only. Adjust this or Temperature, not both"
        defaultValue={promptsConfiguration.completionTopProbability}
        size="small"
        fullWidth
        type="number"
        inputProps={{ min: 0, max: 2, step: 0.1 }}
        {...register(`completionTopProbability`, { min: 0, max: 2 })}
      />
    </Box>
  );
}
