// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export function BoolValue({ value }) {
  return value ? (
    'Yes'
    // <FiberManualRecordIcon style={{ color: 'green' }} />
  ) : (
    'No'
    // <FiberManualRecordIcon style={{ color: 'red' }} />
  );
}
