import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { omit } from 'ramda';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';
import { useRequireLogin } from '../../../contexts/RequiresLoginContext';
import { CLASSES_QUERY_KEY } from './useCoursesList';

const mutationFn = ({ data }) =>
  axios.request({
    method: data.id ? 'patch' : 'post',
    url: `/api/courses/${data.id || ''}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
    data: omit(['id'])(data),
  });

export const useCourseMutation = ({ onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();
  const requireLogin = useRequireLogin();

  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [CLASSES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: ['course', { courseId: response.data.course.id.toString() }],
      });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      if (error.response.data.googleSync) {
        requireLogin();
      } else {
        enqueueSnackbar(error.response.data.error, {
          autoHideDuration: 5000,
          variant: 'error',
        });
      }
      onError(error.response.data);
    },
  });
};
