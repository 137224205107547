import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';

const mutationFn = ({ data }) =>
  axios.request({
    method: data.id ? 'patch' : 'post',
    url: `/api/templates/${data.id || ''}`,
    baseURL: API_BASE_URL,
    withCredentials: true,
    data,
  });

export const useTemplateMutation = ({ onError, onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['teacherTemplates'],
      });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, {
        autoHideDuration: 5000,
        variant: 'error',
      });
      onError(error.response.data);
    },
  });
};
