import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const getCourse = async ({ queryKey }) => {
  const [, { courseId }] = queryKey;

  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/courses/${courseId}`,
      { withCredentials: true }
    );

    return { ...response.data.course };
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Access Denied: You do not have permission to access this course.');
    }
    throw error;
  }
};

export const useCourse = (courseId) =>
  useQuery({
    queryKey: ['course', { courseId }],
    queryFn: getCourse,
    retry: false,
  });
