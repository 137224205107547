import { useEffect, useState } from 'react';
import { socket } from '../utils/sockets';

export const useSocketsStatus = () => {
  const [id, setId] = useState(socket.id);

  useEffect(() => {
    const onConnected = socket.on('connect', () => {
      setId(socket.id);
    });
    const onDisconnected = socket.on('disconnect', () => {
      setId(undefined);
    });

    return () => {
      socket.off('connect', onConnected);
      socket.off('disconnect', onDisconnected);
    };
  }, []);

  return {
    id,
    connected: Boolean(id),
  };
};
