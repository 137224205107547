import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import Replay10Icon from '@mui/icons-material/Replay10';
import Replay30Icon from '@mui/icons-material/Replay30';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward30Icon from '@mui/icons-material/Forward30';
import Forward10Icon from '@mui/icons-material/Forward10';
import { formatPlayerTime } from '../helpers';

const PLAYBACK_SPEEDS = [0.5, 1, 2, 5, 10];

export function PlaybackNavigation({
  children,
  currentTime,
  endTime,
  forward,
  isPaused,
  rewind,
  onPlayPaused,
  setSpeed,
  speed,
}) {
  return (
    <Box>
      <Grid container sx={{ my: 1 }}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <ButtonGroup variant="contained" sx={{ marginRight: 2 }}>
              <Button size="small" onClick={() => rewind(10000)}>
                <Replay10Icon />
              </Button>
              <Button size="small" onClick={() => rewind(30000)}>
                <Replay30Icon />
              </Button>
              <Button size="small" onClick={onPlayPaused} variant="contained">
                {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
              </Button>
              <Button size="small" onClick={() => forward(30000)}>
                <Forward30Icon />
              </Button>
              <Button size="small" onClick={() => forward(10000)}>
                <Forward10Icon />
              </Button>
            </ButtonGroup>
            <Typography>
              {formatPlayerTime(currentTime)} / {formatPlayerTime(endTime)}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }} xs={6}>
          <Box display="flex" alignItems="center" justifyContent="end" gap={1}>
            {children}
            <ButtonGroup
              size="small"
              sx={{
                '.MuiButton-root': {
                  textTransform: 'lowercase !important',
                },
              }}
            >
              {PLAYBACK_SPEEDS.map((s) => (
                <Button
                  key={s}
                  variant={speed === s ? 'contained' : 'outlined'}
                  onClick={() => setSpeed(s)}
                >
                  x{s}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
