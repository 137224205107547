import { useRef, useState } from 'react';
import { Box, Slider } from '@mui/material';
import ReactQuill from 'react-quill';

import 'quill/dist/quill.snow.css';

import { formatPlayerTime } from '../helpers';
import { usePlayerTimeline } from '../api/usePlayerTimeline';
import { PlaybackNavigation } from './PlaybackNavigation';

const SIZES = {
  regular: 400,
  small: 75,
};

export function Player({ children, size = 'regular', steps }) {
  const quillRef = useRef();
  const [speed, setSpeed] = useState(1);
  const {
    currentTime,
    endTime,
    forward,
    isPaused,
    moveToStep,
    rewind,
    togglePlay,
  } = usePlayerTimeline({
    quillRef,
    speed,
    steps,
  });

  return (
    <Box sx={{ my: 1 }} justifyContent="center">
      <Box sx={{ minHeight: SIZES[size] }}>
        <ReactQuill
          formats={[]}
          modules={{ toolbar: false }}
          readOnly
          ref={quillRef}
          style={{ height: '100%' }}
          theme="snow"
        />
      </Box>
      <Slider
        aria-label="time-indicator"
        value={currentTime}
        min={0}
        step={1}
        max={endTime}
        onChange={(_, value) => moveToStep(value)}
        sx={{
          position: 'relative',
          height: 6,
          padding: 0,
          top: -12,
          '& .MuiSlider-track': {
            transition: 'none',
          },
          '& .MuiSlider-thumb': {
            transitionProperty: 'box-shadow',
          },
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={formatPlayerTime}
      />
      <PlaybackNavigation
        currentTime={currentTime}
        endTime={endTime}
        forward={forward}
        isPaused={isPaused}
        rewind={rewind}
        onPlayPaused={togglePlay}
        setSpeed={setSpeed}
        speed={speed}
      >
        {children}
      </PlaybackNavigation>
    </Box>
  );
}
