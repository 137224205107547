import React, { useState, useEffect } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export function TextToSpeech({ text }) {
  const [isSpeechActive, setIsSpeechActive] = useState(false);
  const { speechSynthesis } = window;

  useEffect(() => {
    if (isSpeechActive) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = speechSynthesis.getVoices().find((voice) => voice.name === 'Samantha');
      utterance.lang = 'en-US';
      speechSynthesis.speak(utterance);
    } else {
      speechSynthesis.cancel();
    }

    return () => {
      speechSynthesis.cancel();
    };
  }, [isSpeechActive, text, speechSynthesis]);

  const speechProps = { fontSize: 'small', style: { height: 17, position: 'absolute', left: 0, top: 0 } };

  return (
    <button
      type="button"
      onClick={() => setIsSpeechActive(!isSpeechActive)}
      style={{
        cursor: 'pointer',
        height: 20,
        width: 24,
        position: 'absolute',
        marginLeft: 5,
      }}
    >
      {isSpeechActive ? <VolumeUpIcon {...speechProps} /> : <VolumeOffIcon {...speechProps} />}
    </button>
  );
}
