import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { API_BASE_URL } from '../../config';

const useProfileDataSync = () => {
  const navigate = useNavigate();

  return useMutation(
    async () => {
      // async (userId) => {
      const response = await fetch(`${API_BASE_URL}/api/edlink/profile-data-sync`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ userId }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Sync failed');
      }

      return response.json();
    },
    {
      onSuccess: (data) => {
        navigate('/');
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      },
    }
  );
};

export default useProfileDataSync;

// to jest zupelnie inaczej zrobione bo useQuery?

// const getUser = async () => {
//   const response = await axios.get(`${API_BASE_URL}/auth`, {
//     withCredentials: true,
//   });

//   return response.data.user;
// };

// export const useAuthenticateUser = () =>
//   useQuery({
//     queryKey: ['authenticated-user'],
//     queryFn: getUser,
//     retry: false,
//   });
