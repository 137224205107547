import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { API_BASE_URL } from '../../../../config';

const syncStudents = ({ courseId }) =>
  axios.post(
    `${API_BASE_URL}/api/courses/${courseId}/sync-students`,
    {},
    { withCredentials: true }
  );

export function SynchronizeStudents({ courseId, children }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: syncStudents,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['course', { courseId: courseId.toString() }],
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, { variant: 'error' });
    },
  });

  return (
    <Button
      color="primary"
      variant='outlined'
      size='small'
      disabled={mutation.isLoading}
      onClick={() => mutation.mutate({ courseId })}
    >
      {mutation.isLoading ? (
        <CircularProgress size={15} sx={{ margin: 0.5 }} />
      ) : (
        children
      )}
    </Button>
  );
}
