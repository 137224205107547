import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../config';

const getUser = async () => {
  const response = await axios.get(`${API_BASE_URL}/auth`, {
    withCredentials: true,
  });

  return response.data.user;
};

export const useAuthenticateUser = () =>
  useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getUser,
    retry: false,
  });
