import { createContext } from 'react';
import { useAuthenticateUser } from '../hooks/useAuthenticateUser';
import { LoadingIndicator } from '../components/LoadingIndicator';

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const { data, isLoading } = useAuthenticateUser();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}
