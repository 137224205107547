import { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Link } from '@mui/material';
import { LoadingIndicator } from '../LoadingIndicator';
import { LoadingError } from '../LoadingError';
import { useDefaultPromptsConfiguration } from '../../pages/admin/promptsConfigurations/api/useDefaultPromptsConfiguration';

export function PromptsHelp({ prompt }) {
  const [open, setOpen] = useState(false);
  const { data: promptsConfiguration, isLoading, isError, error } = useDefaultPromptsConfiguration();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Click to view prompt">
        <Button size='small' component={Link} onClick={handleClickOpen}>
          Prompt
        </Button>
      </Tooltip>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
        <DialogTitle>Prompt used to generate response (model: {promptsConfiguration.model}):</DialogTitle>
        <DialogContent>
          <Box sx={{ whiteSpace: 'pre-line' }}>
            <pre>{prompt}</pre>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
