import { Box, Container, Paper, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { useDefaultPromptsConfiguration } from './api/useDefaultPromptsConfiguration';
import { LoadingError } from '../../../components/LoadingError';
import { PostAnalysisPrompts } from './PostAnalysisPrompts';
import { WritingPrompts } from './WritingPrompts';
import { OutlinePrompts } from './OutlinePrompts';
import { AssistantsApiSettings } from './AssistantsApiSettings';

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`prompts-tabpanel-${index}`}
      aria-labelledby={`prompts-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function tabProps(index) {
  return { id: `prompts-tab-${index}`, 'aria-controls': `prompts-tabpanel-${index}` };
}

export function PromptsConfigurationPage() {
  const { data: promptsConfiguration, isLoading, isError, error } = useDefaultPromptsConfiguration();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Prompts configuration">
            <Tab label="Outline" {...tabProps(0)} />
            <Tab label="Writing" {...tabProps(1)} />
            <Tab label="Assistants API Settings" {...tabProps(1)} />
            <Tab label="Post Analysis & Completion API Settings" {...tabProps(2)} />
          </Tabs>
        </Box>
        {/* TODO: Get rid of code duplication in prompt configuration components */}
        <CustomTabPanel value={value} index={0}>
          <OutlinePrompts promptsConfiguration={promptsConfiguration} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <WritingPrompts promptsConfiguration={promptsConfiguration} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AssistantsApiSettings promptsConfiguration={promptsConfiguration} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PostAnalysisPrompts promptsConfiguration={promptsConfiguration} />
        </CustomTabPanel>
      </Paper>
    </Container>
  );
}
