import { Box, TextField } from '@mui/material';

export function StudentRow({
  children,
  disabled,
  errors,
  id,
  name,
  student,
  onChange,
  onSubmit,
}) {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '5fr 5fr 1fr', gap: 2 }}>
      <input type="hidden" name={`${name}-id`} value={student.id} />
      <TextField
        defaultValue={!onChange ? student?.name || '' : undefined}
        disabled={disabled}
        error={Boolean(errors?.name)}
        fullWidth
        helperText={errors?.name || ''}
        id={`${id}-name`}
        label="Student name"
        name={`${name}-name`}
        onChange={onChange ? (e) => onChange('name', e) : undefined}
        InputProps={{
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit?.();
            }
          },
        }}
        size="small"
        value={onChange ? student?.name : undefined}
        variant="filled"
      />
      {children}
    </Box>
  );
}
