import { Container, Paper } from '@mui/material';
import { AssignmentForm } from '../components/AssignmentForm';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

export function AssignmentNewPage() {

  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <AssignmentForm />
      </Paper>
    </Container>
  );
}
