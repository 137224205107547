import { useContext, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext';
import { PenGwen } from './PenGwen';
import { PenGwenButton } from './PenGwenButton';
import { PromptsHelp } from './PromptsHelp';
import { useWhatDoYouThink } from '../../hooks/ai/useWhatDoYouThink';
import { useWriteSthForMe } from '../../hooks/ai/useWriteSthForMe';
import { API_BASE_URL } from '../../config';

export function PenGwenWriting({ assignment, onPromptResult, text, outlineText, outlineStructure, grade, threadId }) {
  const user = useContext(UserContext);
  const [promptResult, setPromptResult] = useState(`Hi there! I'm here to help you write your assignment.`);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleLoading = (value) => {
    setLoading(value);
    onPromptResult(value);
  };

  const handleSuggestionClick = useWhatDoYouThink({
    assignmentId: assignment.id,
    onLoading: handleLoading,
    setPromptResult,
    setPrompt,
    text,
    grade,
    outlineText,
    outlineStructure,
  });

  const generateText = useWriteSthForMe({
    assignmentId: assignment.id,
    onLoading: handleLoading,
    setPromptResult,
    setPrompt,
    text,
    grade,
    outlineText,
    outlineStructure,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <PenGwen
        promptResult={promptResult}
        textToSpeech
        sx={{
          position: 'sticky',
          top: '0',
          bottom: '16px',
          overflow: 'hidden',
          paddingTop: 4,
        }}
      >
        <PenGwenButton onClick={handleSuggestionClick} loading={isLoading}>
          What do you think?
        </PenGwenButton>
        <PenGwenButton onClick={generateText} loading={isLoading}>
          Write something for me
        </PenGwenButton>
        {user.isAdmin && (
          <>
            {prompt && <PromptsHelp prompt={prompt} />}
            {threadId && (
              <Button size='small' component={Link} to={`${API_BASE_URL}/api/thread/${threadId}.html`} target="_blank">
                Thread
              </Button>
            )}
          </>
        )}
      </PenGwen>
    </Box>
  );
}
