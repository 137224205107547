import { useState } from 'react';
import { IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top">
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    padding: theme.typography.pxToRem(10),
  },
}));

export function InfoTooltip({ text }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledTooltip open={open} onClose={handleClose} onOpen={handleOpen} title={text}>
      <IconButton
        aria-label="Open help for field"
        onClick={handleOpen}
        size="small"
        sx={{ paddingY: 0, paddingX: 1 }}
        tabIndex={-1}
      >
        <HelpIcon fontSize="inherit" />
      </IconButton>
    </StyledTooltip>
  );
}
