import { useCallback, useState } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../../config';

export const useCheckMyWork = ({
  assignmentId,
  outlineItemContext,
  grade,
  outlineStructure,
  outlineText,
  templateName,
  type,
}) => {
  const [result, setResult] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const callEndpoint = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/ai/outline`,
        {
          assignmentId,
          outlineItem: outlineItemContext,
          grade,
          templateName,
          outlineStructure,
          outlineText,
          clientTime: performance.timeOrigin + performance.now(),
          type
        },
        { withCredentials: true }
      );

      setResult({ outlineItemContext, ...response.data });
      setError('');
    } catch (e) {
      setResult(undefined);
      setError(e.response.data?.error || "I can't help you right now, sorry!");
    } finally {
      setLoading(false);
    }
  }, [assignmentId, outlineItemContext]);

  return {
    data: result,
    isLoading,
    isError: Boolean(error),
    error,
    callEndpoint,
  };
};
