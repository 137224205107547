import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const getUserAssignments = async (assignmentId) => {
  const response = await axios.get(`${API_BASE_URL}/api/assignments/${assignmentId}/asignees`, {
    withCredentials: true,
  });

  return response.data;
};

export const useUserAssignments = (assignmentId) =>
  useQuery(['userAssignments', assignmentId], () => getUserAssignments(assignmentId), {
    enabled: !!assignmentId, // TODO: Maybe apply for all queries?
    retry: false,
  });
