import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  // IconButton,
  // InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  // TextField,
  Typography,
} from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Edit as EditIcon } from '@mui/icons-material';
// import EventIcon from '@mui/icons-material/Event';
// import { DatePicker } from '@mui/x-date-pickers';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { useAssignment } from '../../api/useAssignment';
import { useCoursesList } from '../../../classes/api/useCoursesList';
import { UserAvatar } from '../../../../components/Avatar';
import { useUserAssignmentsMutation } from '../../api/useUserAssignmentsMutation';
import { ROUTES } from '../../../../routes';
import { useUserAssignments } from '../../api/useUserAssignments';

function SaveButton({ isLoading, onclick }) {
  return (
    <Button
      variant="contained"
      disabled={isLoading}
      name="save"
      type="submit"
      startIcon={<SaveAsIcon />}
      onClick={onclick}
    >
      {isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Publish to selected students/classes'}
    </Button>
  );
}

export function AssignmentAssignPage() {
  const { assignmentId } = useParams();
  const [selectedCourses, setSelectedCourses] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const navigate = useNavigate();

  const { data: courses, isLoading: isLoadingCourses, isError: isErrorCourses, error: errorCourses } = useCoursesList();

  const {
    data: assignment, // TODO: In practice I need it only to display title in Breadcrumb - consider refactoring
    isLoading: isLoadingAssignment,
    isError: isErrorAssignment,
    error: errorAssignment,
  } = useAssignment(assignmentId);

  const {
    data: asignees,
    isLoading: isLoadingAsignees,
    isError: isErrorAsignees,
    error: errorAssignees,
  } = useUserAssignments(assignmentId);

  const userAssignmentsMutation = useUserAssignmentsMutation(assignment?.id, {
    // eslint-disable-next-line no-unused-vars
    onSuccess: (result) => {
      navigate(ROUTES.showAssignment(assignment.id));
    },
    onError: (result) => {
      console.log('User Assignment error', result);
    },
  });

  useEffect(() => {
    if (asignees) {
      const currentCheckedItems = Object.fromEntries(asignees.map((userId) => [userId, true]));
      setCheckedItems(currentCheckedItems);
    }
  }, [asignees]);

  // useEffect(() => {
  //   console.log(checkedItems);
  // }, [checkedItems]);

  if (isLoadingAssignment || isLoadingCourses || isLoadingAsignees) {
    return <LoadingIndicator />;
  }

  if (isErrorAssignment || isErrorCourses || isErrorAsignees) {
    return <LoadingError error={errorAssignment || errorCourses || errorAssignees} />;
  }

  const handleToggleAll = (course) => {
    const courseId = course.id;
    setSelectedCourses((prevState) => ({ ...prevState, [courseId]: !prevState[courseId] }));

    const newCheckedItems = { ...checkedItems };
    course.courseUsers.forEach((courseUser) => {
      newCheckedItems[courseUser.user.id] = !selectedCourses[courseId];
    });
    setCheckedItems(newCheckedItems);
  };

  const handleToggle = (userId) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      newCheckedItems[userId] = !newCheckedItems[userId];
      return newCheckedItems;
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const userIds = Object.keys(checkedItems)
      .filter((key) => checkedItems[key])
      .map(Number);
    // TODO: it's wrong right now!
    userAssignmentsMutation.mutate({ data: userIds }); // TODO: We have to separate true / false
    // userAssignmentsMutation.mutate({ data: checkedItems });
  };

  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
          <Button
            label="Back to Edit"
            to={ROUTES.editAssignment(assignmentId)}
            icon={<EditIcon />}
            variant="outlined"
          />
          <SaveButton isLoading={userAssignmentsMutation.isLoading} onclick={(e) => handleSave(e)} />
        </Box>
        <Grid container spacing={2}>
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.name}>
              <Typography variant="h5" paddingLeft="15px">
                {course.name}
              </Typography>
              <FormControlLabel
                sx={{ paddingLeft: '15px' }}
                control={
                  <Checkbox checked={selectedCourses[course.id] || false} onChange={() => handleToggleAll(course)} />
                }
                label="Select All"
              />
              <List dense style={{ width: 'fit-content' }}>
                {course.courseUsers.map((courseUser) => {
                  const labelId = `checkbox-list-secondary-label-${courseUser.user.name}`;
                  return (
                    <ListItem
                      key={courseUser.user.name}
                      disablePadding
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      <ListItemButton onClick={() => handleToggle(courseUser.user.id)}>
                        <Checkbox
                          edge="start"
                          style={{ paddingTop: '2px', paddingBottom: '2px' }}
                          checked={checkedItems[courseUser.user.id] || false}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemAvatar style={{ minWidth: 'auto', width: '32px' }}>
                          <UserAvatar alt={courseUser.user.name} src={courseUser.user.avatarUrl} />
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={courseUser.user.name} />
                      </ListItemButton>
                      {/* {checkedItems[courseUser.user.id] &&
                          <DatePicker
                            // label="Due date"
                            // onChange={handleDueDateChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // error={!isEmpty(formErrors.dueDate)}
                                // helperText={formErrors.dueDate}
                                name="dueDate"
                                size="small"
                                variant="standard"
                                style={{ width: '130px' }}
                                // InputProps={{
                                  // disableUnderline: true,
                                  //   endAdornment: (
                                  //     <InputAdornment position="end">
                                  //       <IconButton edge="end" style={{ padding: '0 3px 0 3px' }}>
                                  //         <EventIcon style={{ fontSize: '1rem' }} />
                                  //       </IconButton>
                                  //     </InputAdornment>
                                  //   ),
                                // }}
                              />
                            )}
                            // shouldDisableDate={dueDateValidation}
                            value={assignment.dueDate}
                          />
                        } */}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
          <SaveButton isLoading={userAssignmentsMutation.isLoading} onclick={(e) => handleSave(e)} />
        </Box>
      </Paper>
    </Container>
  );
}
