import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';
import { parseDateWithoutTimezone } from '../../../utils/date';

const getAssignment = async ({ queryKey }) => {
  const [, { assignmentId }] = queryKey;

  const response = await axios.get(
    `${API_BASE_URL}/api/assignments/${assignmentId}`,
    { withCredentials: true }
  );

  return {
    ...response.data.assignment,
    dueDate: response.data.assignment.dueDate
      ? parseDateWithoutTimezone(response.data.assignment.dueDate)
      : undefined,
  };
};

export const useAssignment = (assignmentId) =>
  useQuery({
    queryKey: ['assignment', { assignmentId }],
    queryFn: getAssignment,
    retry: false,
  });
