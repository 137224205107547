import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { useForm } from 'react-hook-form';
import { usePromptsConfigurationUpdateMutation } from './api/usePromptsConfigurationUpdateMutation';

export function AssistantsApiSettings({ promptsConfiguration }) {
  const { register, handleSubmit } = useForm();
  const mutation = usePromptsConfigurationUpdateMutation();
  const saving = mutation.isLoading;

  const onSubmit = (data) => {
    mutation.mutate({ data, promptsConfigurationId: promptsConfiguration.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id="assistants-model-label">Model</InputLabel>
        <Select
          labelId="assistants-model-label"
          id="assistants-model"
          defaultValue={promptsConfiguration.assistantsModel}
          label="Model"
          size="small"
          {...register(`assistantsModel`)}
        >
          <MenuItem value="gpt-4">GPT-4</MenuItem>
          <MenuItem value="gpt-4o">GPT-4o</MenuItem>
          <MenuItem value="gpt-4o-mini">GPT-4o mini</MenuItem>
          <MenuItem value="gpt-4-32k">GPT-4 32k</MenuItem>
          <MenuItem value="gpt-3.5-turbo">GPT-3.5</MenuItem>
          <MenuItem value="gpt-3.5-turbo-16k">GPT-3.5 16k</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Maximum Prompt Tokens"
        helperText="The maximum number of prompt tokens that may be used over the course of the run. The run will make a best effort to use only the number of prompt tokens specified, across multiple turns of the run. If the run exceeds the number of prompt tokens specified, the run will end with status incomplete"
        defaultValue={promptsConfiguration.assistantsMaxPromptTokens}
        size="small"
        fullWidth
        sx={{ my: 2 }}
        type="number"
        inputProps={{ min: 100, max: 8192, step: 1 }}
        {...register(`assistantsMaxPromptTokens`, { min: 100, max: 8192 })}
      />
      <TextField
        label="Maximum Completion Tokens"
        helperText="The maximum number of completion tokens that may be used over the course of the run. The run will make a best effort to use only the number of completion tokens specified, across multiple turns of the run. If the run exceeds the number of completion tokens specified, the run will end with status incomplete"
        defaultValue={promptsConfiguration.assistantsMaxCompletionTokens}
        size="small"
        fullWidth
        sx={{ my: 2 }}
        type="number"
        inputProps={{ min: 100, max: 8192, step: 1 }}
        {...register(`assistantsMaxCompletionTokens`, { min: 100, max: 8192 })}
      />
      <TextField
        label="Temperature"
        helperText="What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."
        defaultValue={promptsConfiguration.assistantsTemperature}
        size="small"
        fullWidth
        sx={{ my: 2 }}
        type="number"
        inputProps={{ min: 0, max: 2, step: 0.1 }}
        {...register(`assistantsTemperature`, { min: 0, max: 2 })}
      />
      <TextField
        label="Top Probability"
        helperText="An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered."
        defaultValue={promptsConfiguration.assistantsTopProbability}
        size="small"
        fullWidth
        type="number"
        inputProps={{ min: 0, max: 2, step: 0.1 }}
        {...register(`assistantsTopProbability`, { min: 0, max: 2 })}
      />
      {/*
      https://platform.openai.com/docs/api-reference/runs/createRun#runs-createrun-truncation_strategy
       <TextField
        label="Truncation Strategy"
        helperText="Controls for how a thread will be truncated prior to the run. Use this to control the intial context window of the run."
        defaultValue={promptsConfiguration.assistantsTopProbability}
        size="small"
        fullWidth
        type="number"
        inputProps={{ min: 0, max: 2, step: 0.1 }}
        {...register(`assistantsTopProbability`, { min: 0, max: 2 })}
      /> */}
    </Box>

      <Button variant="contained" type="submit" disabled={saving}>
        {saving && <CircularProgress sx={{ marginRight: 2 }} size={20} />}
        Save
      </Button>
    </form>
  );
}
