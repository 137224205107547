import { Button, Container, Paper, Typography } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { LoadingError } from '../../components/LoadingError';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { useCoursesList } from './api/useCoursesList';
import { ROUTES } from '../../routes';
import { AddButton } from '../../components/AddButton';

export function ClassesIndexPage() {
  const { data: courses, isLoading, isError, error } = useCoursesList();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  if (courses.length > 0) {
    return <Navigate replace to={ROUTES.showClass(courses[0].id)} />;
  }

  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <Typography variant="h5">
          You do not have any classes yet
          <AddButton to={ROUTES.newClass()} />
        </Typography>
        <Button variant="contained" color="primary" component={Link} to={ROUTES.newClass()} sx={{ marginY: 3 }}>
          Create your first class
        </Button>
      </Paper>
    </Container>
  );
}
