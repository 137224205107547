import { Container, Paper, Typography } from '@mui/material';
import { Breadcrumb } from '../../components/layout/Breadcrumb';

export default function ErrorPage({ error = 'Something went wrong', assignmentId, assignmentTitle, userId, username }) {
  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignmentTitle} userId={userId} username={username} />
      <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }} marginBottom={2} align="center">
          {error}
        </Typography>
      </Paper>
    </Container>
  );
}
