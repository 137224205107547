import { GoogleIcon } from './GoogleIcon';

export function GoogleLoginButton({ onClick, label = '' }) {
  return (
    <button className="gsi-material-button" onClick={onClick} type="button">
      <div className="gsi-material-button-state" />
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <GoogleIcon />
        </div>
        <span className="gsi-material-button-contents">{label}</span>
      </div>
    </button>
  );
}
