import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'ramda';

const ERROR_MESSAGES = {
  name: 'You must enter a name for this section/component',
  shortDescription: 'You must enter a short description for this component',
};

const EMPTY_ERRORS = {
  name: '',
  shortDescription: '',
};

export function Form({ selectedComponent, handleEditComponent }) {
  const [formValues, setFormValues] = useState(selectedComponent);
  const [formErrors, setFormErrors] = useState(EMPTY_ERRORS);

  useEffect(() => {
    setFormValues(selectedComponent);
    // TODO: reset form errors when component changes
  }, [selectedComponent]);

  // const validateField = (values) => {
  //   const errors = { ...EMPTY_ERRORS };

  //   if (isEmpty(values.name)) {
  //     errors.name = ERROR_MESSAGES.name;
  //   }

  //   setFormErrors(errors);

  //   return !Object.values(errors).some(Boolean);
  // };

  const isComponentType = (component) => component.id.startsWith('component-');

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    if (value.trim() === '' && (!isEmpty(formErrors[name]) || isEmpty(formValues[name]))) {
      // If the value is blank and there is already an error message, return to prevent single space hit
      return;
    }

    handleEditComponent(selectedComponent.id, { [name]: value });
    setFormValues({ ...formValues, [name]: value });

    setFormErrors((currentErrors) => {
      if (isEmpty(value)) {
        return {
          ...currentErrors,
          [name]: ERROR_MESSAGES[name],
        };
      }

      if (!isEmpty(currentErrors[name])) {
        return {
          ...currentErrors,
          [name]: '',
        };
      }

      return currentErrors;
    });
  };

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        value={formValues.name}
        error={!isEmpty(formErrors.name)}
        fullWidth
        helperText={
          formErrors.name ||
          (isComponentType(selectedComponent)
            // ? 'Name this section, something like "Key Context"'
            ? ''
            : 'Name this section, something like "Introduction" or "Paragraph 1"')
        }
        id="name"
        label={isComponentType(selectedComponent) ? 'Component Name' : 'Section Name'}
        name="name"
        onChange={handleFieldChange}
        onFocus={(e) => e.target.select()}
        variant="outlined"
      />

      {isComponentType(selectedComponent) && (
        <TextField
          value={formValues.shortDescription}
          error={!isEmpty(formErrors.shortDescription)}
          fullWidth
          helperText={
            formErrors.shortDescription // || 'A short description of the component'
          }
          id="shortDescription"
          label="Component Description"
          name="shortDescription"
          onChange={handleFieldChange}
          onFocus={(e) => e.target.select()}
          variant="outlined"
          multiline
          minRows={3}
        />
      )}
    </Box>
  );
}
