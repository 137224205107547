import { createContext, useCallback, useContext, useState } from 'react';
import { propOr } from 'ramda';

// eslint-disable-next-line no-unused-vars
const UpdateStepDataContext = createContext((step, data) => {});
const StepsDataContext = createContext({});

export function StepsDataContextProvider({ children, steps }) {
  const [outlineSteps, setOutlineSteps] = useState(steps);

  const updateStep = useCallback((stepId, newData) => {
    setOutlineSteps((currentOutlineSteps) => ({
      ...currentOutlineSteps,
      [stepId]: newData.value, // override with new value
    }));
    //
  }, []);

  return (
    <UpdateStepDataContext.Provider value={updateStep}>
      <StepsDataContext.Provider value={outlineSteps}>{children}</StepsDataContext.Provider>
    </UpdateStepDataContext.Provider>
  );
}

export const useStepsData = () => useContext(StepsDataContext);
// TODO: Export to external file as hook?
export const useStepData = (stepId) => {
  const outlineSteps = useStepsData();
  return propOr({}, stepId, outlineSteps);
};

export const useUpdateStepData = () => useContext(UpdateStepDataContext);
  