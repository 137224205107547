import { Container, Paper } from '@mui/material';
import { useContext } from 'react';
import { LoadingError } from '../../../components/LoadingError';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { useRequireLogin } from '../../../contexts/RequiresLoginContext';
import { useGoogleCoursesList } from '../api/useGoogleCoursesList';
import { ClassForm } from '../components/ClassForm';
import { UserContext } from '../../../contexts/UserContext';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

const formWithGoogleClassroms = (requireLogin) => {
  const {
    data: coursesList,
    isLoading,
    isError,
    error,
  } = useGoogleCoursesList();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError && error.response?.data?.googleSync) {
    requireLogin();
    return null;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      {isLoading && <LoadingIndicator />}
      <ClassForm googleCourses={coursesList} />
    </Paper>
  );
};

export function ClassNewPage() {
  const user = useContext(UserContext);
  const requireLogin = useRequireLogin();
  const isGoogleAccount = user.externalSource === 'google';

  return (
    <Container>
      <Breadcrumb />
      {isGoogleAccount ? (
        formWithGoogleClassroms(requireLogin)
      ) : (
        <Paper sx={{ padding: 2 }}>
          <ClassForm />
        </Paper>
      )}
    </Container>
  );
}
