import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { LoadingIndicator } from '../../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../../components/LoadingError';
import { StudentAssignmentsList } from './StudentAssignmentsList';
import { Breadcrumb } from '../../../../../components/layout/Breadcrumb';
import { useCourseStudent } from '../../../api/useCourseStudent';

export function StudentPage() {
  const { courseId, studentId } = useParams();
  // TODO: what if we detach 
  const { data: student, isLoading, isError, error } = useCourseStudent(courseId, studentId);

  if (isLoading || !student) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb
        courseId={student.course.id}
        courseName={student.course.name}
        userId={studentId}
        username={student.name}
      />
      <StudentAssignmentsList assignments={student.assignments} student={student} studentId={studentId} />
    </Container>
  );
}
