import { Add as AddIcon } from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

export function AddButton({ path, tooltip, sx, size = 'large', color = 'primary' }) {
  return (
    <Tooltip title={tooltip} style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
      <Fab color={color} size={size} component={Link} to={path} sx={sx}>
        <AddIcon sx={{ color: 'white' }} />
      </Fab>
    </Tooltip>
  );
}
