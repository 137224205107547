import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditIcon from '@mui/icons-material/Edit';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export function SpeedDialAddButton({ actions = [], onOpen, onClose}) {
  const navigate = useNavigate();
  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionRight': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

    const handleOpen = () => {
      if (onOpen) {
        onOpen();
      }
    };

    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };

  return (
    <StyledSpeedDial
      ariaLabel="SpeedDial actions"
      // icon={<SpeedDialIcon />}
      icon={<EditIcon />}
      direction="up"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => navigate(action.route)}
        />
      ))}
    </StyledSpeedDial>
  );
}
