import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledButtonBlock } from './StyledButtonBlock';
import { DeleteTemplateButton } from './DeleteTemplateButton';

export function SortableButton({ children, ...props }) {
  const { selected, onClick, value, sortable, onDelete, onCopy, onEdit, incomplete, editMode } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} position="relative">
      <StyledButtonBlock
        selected={selected}
        incomplete={incomplete}
        onClick={onClick}
        value={value}
        editMode={editMode}
      >
        {children}
        {sortable && selected && (
          <DragHandleIcon
            style={{
              position: 'absolute',
              left: 20,
              top: '50%',
              transform: 'translateY(-50%) translateX(-50%)',
            }}
            className="drag-handle"
          />
        )}
      </StyledButtonBlock>
      {isHovered && (
        <>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 6,
              width: '104px',
              height: '28px',
              position: 'absolute',
              top: '2px',
              right: '2px',
            }}
          />
          <Stack
            direction="row"
            spacing={0}
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            {!editMode && sortable && (
              <Tooltip title="Edit Template">
                <IconButton color="primary" onClick={onEdit}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Duplicate">
              <IconButton color="primary" onClick={onCopy}>
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {sortable && (
              <>
                {editMode && (
                  <IconButton color="error" onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}

                {!editMode && (
                  <Tooltip title="Delete">
                    <DeleteTemplateButton deleteHandler={onDelete} />
                  </Tooltip>
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}
