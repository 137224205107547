import { IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

export function IconButtonWithTooltip({
  tooltip,
  icon,
  to,
  disabled,
  style,
  size = 'small',
  onClick,
  tooltipPlacement = 'left',
}) {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <IconButton component={Link} to={to} disabled={disabled} size={size} style={style} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}
