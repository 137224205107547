import { Container, Paper } from '@mui/material';
import { Form } from './components/Form';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

export function AddUser() {
  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <Form />
      </Paper>
    </Container>
  );
}
