import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../../../../config';

const getUsers = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/admin/users`, {
    withCredentials: true,
  });

  return response.data.users;
};

export const useUsers = () =>
  useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
    retry: false,
  });
