import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { API_BASE_URL } from '../../../config';

const buildUpdateQuery =
  (assignmentId) =>
  ({ data }) =>
    axios.request({
      method: 'put',
      url: `/api/assignments/${assignmentId}/asignees`,
      baseURL: API_BASE_URL,
      withCredentials: true,
      data,
    });

export const useUserAssignmentsMutation = (assignmentId, { onError, onSuccess } = {}) =>
  useMutation({
    mutationFn: buildUpdateQuery(assignmentId),
    onSuccess: (response) => {
      enqueueSnackbar('Students assigned!', { variant: 'success' });
      onSuccess?.(response.data);
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.error, { autoHideDuration: 5000, variant: 'error' });
      onError(error.response.data);
    },
  });
