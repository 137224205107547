import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { isEmpty } from 'ramda';
import { Close as CloseIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const getHelperText = (course, googleCourses) => {
  if (course) {
    return 'This class is synchronized with Google Classroom course';
  }

  if (isEmpty(googleCourses)) {
    return 'You have no additional classes to import from Google Classroom';
  }

  return 'Synchronize your new class with Google Classroom course';
};

export function GoogleCourseField({
  course,
  googleCourses,
  googleCourseId,
  onChange,
}) {
  const [selectedCourseId, setSelectedCourseId] = useState(googleCourseId || '');

  useEffect(() => {
    setSelectedCourseId(googleCourseId || '');
  }, [googleCourseId]);

  const handleGoogleClassroomChange = (e) => {
    const classroom = googleCourses.find((c) => c.id === e.target.value);
    setSelectedCourseId(e.target.value);
    onChange({
      name: classroom.name,
      description: classroom.description,
      googleCourseId: classroom.id,
    });
  };

    const handleClearSelection = () => {
      setSelectedCourseId('');
      onChange({ name: '', description: '', googleCourseId: '' });
    };

  const disabled = Boolean(course) || isEmpty(googleCourses);

  return (
    <TextField
      value={selectedCourseId}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ display: !disabled && googleCourseId ? '' : 'none' }}>
            <IconButton aria-label="clear selection" onClick={handleClearSelection} edge="end">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      helperText={getHelperText(course, googleCourses)}
      id="course-id"
      label="Select a Google Classroom class you want to start with:"
      onChange={handleGoogleClassroomChange}
      name="googleCourseId"
      select
      sx={{
        '& .MuiSelect-icon': {
          display: !disabled && googleCourseId ? 'none' : '',
        },
      }}
      variant="filled"
    >
      {course && googleCourseId && <MenuItem value={googleCourseId}>{course.googleCourseName}</MenuItem>}
      {!course &&
        googleCourses.map((googleCourse) => (
          <MenuItem key={googleCourse.id} value={googleCourse.id.toString()}>
            {googleCourse.name}
          </MenuItem>
        ))}
    </TextField>
  );
}
