import { useState } from 'react';
import { Box, FormControl, FormLabel, Typography } from '@mui/material';
import { ExistingStudentRow } from './ExistingStudentRow';
import { NewStudentRow } from './NewStudentRow';
import { CsvImportButton } from './CsvImportButton';
// import { SynchronizeStudents } from './google/SynchronizeStudents';

export function StudentsListField({ defaultValue, googleClass, id, label, name, course }) {
  const [items, setItems] = useState(defaultValue);

  const handleNewStudent = (student) => {
    setItems((currentItems) => [...currentItems, student]);
  };
  const handleRemoveStudent = (student) => {
    setItems((currentItems) => currentItems.filter((s) => s !== student));
  };

  return (
    <FormControl sx={{ marginY: 2 }}>
      <FormLabel sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
        <Typography sx={{ flexGrow: 1 }}>{label}</Typography>
        {!googleClass && <CsvImportButton onItemAdded={handleNewStudent} />}
      </FormLabel>
      {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <ButtonGroup size="small">
          {googleClass && (
            <SynchronizeStudents courseId={course?.id}>
              Synchronize students list with Google Classroom
            </SynchronizeStudents>
          )}
        </ButtonGroup>
      </Box> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          gap: 1,
        }}
      >
        {items.map((item, idx) => (
          <ExistingStudentRow
            key={item.id}
            disabled={googleClass}
            id={`${id}-${idx}`}
            name={name}
            onRemove={handleRemoveStudent}
            student={item}
          />
        ))}
        {googleClass ? (
          <Box>
            {!course &&
            <Typography sx={{ marginY: 2, textAlign: 'center' }}>
              Students will be imported from selected Google Classroom
            </Typography>
            }
          </Box>
        ) : (
          <NewStudentRow id={id} name={name} onAdd={handleNewStudent} />
        )}
      </Box>
    </FormControl>
  );
}
