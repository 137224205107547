import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { WritingStats } from '../../../components/WritingStats';
import { TextEditor } from '../../../../../components/TextEditor';
import { PenGwenWriting } from '../../../../../components/pen-gwen/PenGwenWriting';
import { removeWhitespaces } from '../../../../../utils/text';
import { CompletedOutlinePreview } from '../../../components/CompletedOutlinePreview';
import { getOutlineText } from '../../../../../helpers/template';
import { useStepsData, useUpdateStepData } from '../../../../../contexts/StepsDataContext';
import { STEPS } from '../../../../../helpers/constants';

export function Stage2Writing({
  assignment,
  userAssignment,
  writingText,
  setSubmissionData,
  useOutline,
  outlineStructure,
  templeteOutlineTree,
  grade,
}) {
  const [text, setText] = useState(''); // TODO: set default?
  const [internalTextSelectEvents, setInternalTextSelectEvents] = useState([]);
  const internalTextSelectEventsRef = useRef(internalTextSelectEvents);
  const [externalTextPasteEvents, setExternalTextPasteEvents] = useState([]);
  const [promptResultLoading, setPromptResultLoading] = useState(false);
  const [writingStats, setWritingStats] = useState({});
  const handleWritingStatsChange = (statsObj) => setWritingStats(statsObj);
  const outlineSteps = useStepsData();
  const updateWritingStepValue = useUpdateStepData();

  useEffect(() => {
    internalTextSelectEventsRef.current = internalTextSelectEvents;
  }, [internalTextSelectEvents]);

  const handleOnTextPaste = (pastedText) => {
    const normalizedPastedText = removeWhitespaces(pastedText);
    const isExternalSource = !internalTextSelectEventsRef.current.includes(normalizedPastedText);
    if (isExternalSource) {
      setExternalTextPasteEvents((prevValue) => [...prevValue, normalizedPastedText]);
    }
    return pastedText;
  };

  useEffect(() => {
    setSubmissionData((prevState) => ({ ...prevState, writingText: text, writingStats }));
    updateWritingStepValue(STEPS.WRITING_TEXT, { value: text });
  }, [text, writingStats]);

  useEffect(() => {
    const handleMouseUp = () => {
      const selectedText = removeWhitespaces(window.getSelection().toString());
      if (selectedText !== '') {
        setInternalTextSelectEvents((prevValue) => [...prevValue, selectedText]);
      }
    };

    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [internalTextSelectEvents]);

  const outlineText = useOutline ? getOutlineText(templeteOutlineTree, outlineSteps) : '';

  return (
    <Grid container spacing={2}>
      {useOutline && (
        <Grid item md={3} xs={12}>
          {/* najlepiej by bylo tutaj przekazac  strukture sections/components z wartosciami? ale to duzo przepisywania */}
          <CompletedOutlinePreview templeteOutlineTree={templeteOutlineTree} outlineSteps={outlineSteps} />
        </Grid>
      )}
      <Grid item md={useOutline ? 6 : 9} xs={12}>
        <Typography sx={{ paddingBottom: 2, textAlign: 'center' }} variant="h6">
          Your writing assignment
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <TextEditor
            containerStyles={{ display: 'flex', minHeight: 600, mb: 2 }}
            quillStyles={{ flex: 1, overflow: 'auto' }}
            disabled={promptResultLoading}
            initialValue={writingText}
            onTextChange={setText}
            onTextPaste={handleOnTextPaste}
            userAssignmentId={userAssignment.id}
            step={STEPS.WRITING_TEXT}
            internalTextSelectEvents={internalTextSelectEvents}
            externalTextPasteEvents={externalTextPasteEvents}
          />
          <WritingStats
            text={text}
            externalTextPasteEvents={externalTextPasteEvents}
            onStatsChange={handleWritingStatsChange}
          />
        </Paper>
      </Grid>
      <Grid item md={3} xs={12} sx={{ position: 'relative' }}>
        {assignment.useGwen && (
          <PenGwenWriting
            assignment={assignment}
            onPromptResult={setPromptResultLoading}
            text={text}
            grade={grade}
            outlineText={outlineText}
            outlineStructure={outlineStructure}
            threadId={userAssignment.writingThreadId}
          />
        )}
      </Grid>
    </Grid>
  );
}
