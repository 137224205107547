import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { ReactSortable } from 'react-sortablejs';
import { SortableButton } from './components/SortableButton';
import { TemplateContext } from './TemplateProvider';
import { useTemplateDeleteMutation } from './api/useTemplateDeleteMutation';
import { ROUTES } from '../../routes';
import { shorten } from '../../utils/text';

const MAX_NAME_LENGTH = 65;

export function TemplatesList({ templates, sortable }) {
  const [items, setItems] = useState(templates);
  const { selectedTemplateId, setSelectedTemplateId } = useContext(TemplateContext);
  const navigate = useNavigate();

  const mutation = useTemplateDeleteMutation();

  useEffect(() => {
    setItems(templates);
  }, [templates]);

  const selectTemplate = (e) => {
    setSelectedTemplateId(e.target.value);
  };

  const deleteTemplate = (templateId) => {
    mutation.mutate(templateId);
  };

  const editTemplate = (templateId) => {
    navigate(ROUTES.editTemplate(templateId), { state: { templateId } });
  };

  const duplicateTemplate = (templateId) => {
    navigate(ROUTES.TEMPLATES_NEW, { state: { templateId } });
  };

  if (!items) {
    // TODO REMOVE and handle empty list & changes of order to backend
    setItems([]);
  }

  return (
    <Grid container direction="column">
      {items.length === 0 && (
        <Grid item>
          <Typography textAlign="center">Use &apos;Add&apos; button to create your first template!</Typography>
        </Grid>
      )}
      <ReactSortable
        list={items}
        setList={setItems}
        handle=".drag-handle"
        animation="200"
        dragClass="sortableDrag"
        easing="cubic-bezier(0.1, -0.6, 0.2, 0)"
        swapThreshold={0.7}
        disabled={!sortable}
      >
        {items.map((item) => (
          <Grid item key={item.id} sx={{ marginBottom: 0.5 }}>
            <SortableButton
              sortable={sortable}
              value={item.id}
              selected={selectedTemplateId === item.id}
              onClick={selectTemplate}
              onDelete={() => deleteTemplate(item.id)}
              onCopy={() => duplicateTemplate(item.id)}
              onEdit={() => editTemplate(item.id)}
            >
              {shorten(item.name, MAX_NAME_LENGTH)}
            </SortableButton>
          </Grid>
        ))}
      </ReactSortable>
    </Grid>
  );
}
