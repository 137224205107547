import { removeWhitespaces } from '../utils/text';

const getTemplateSections = (templateComponents) =>
  templateComponents.filter((component) => component.parentId === null);
const getTemplateComponents = (templateComponents) =>
  templateComponents.filter((component) => component.parentId !== null);

export const createOutlineTree = (templateComponents) => {
  if (!templateComponents) return [];

  const parents = getTemplateSections(templateComponents);
  const children = getTemplateComponents(templateComponents);
  const steps = parents.map((parent) => ({ ...parent, components: [] }));

  children.forEach((child) => {
    const step = steps.find((p) => p.id === child.parentId);

    if (step) {
      step.components.push(child);
    }
  });

  return steps;
};

// TODO: not completed
// eslint-disable-next-line no-unused-vars
// export const createOutlineTreeWithData = (templateComponents, completedOutlineSteps) => {
//   if (!templateComponents) return [];

//   const sections = templateComponents.filter((component) => component.parentId === null);
//   const components = templateComponents.filter((component) => component.parentId !== null);

//   const steps = sections.map((section) => ({ ...section, components: [] }));

//   // For each child, find its parent and add it to the parent's 'substeps' array
//   components.forEach((component) => {
//     const step = steps.find((p) => p.id === component.parentId);

//     if (step) {
//       step.components.push(component);
//     }
//   });

//   return steps;
// };

export const getOutlineStructure = (templeteOutlineTree) => {
  let outlineStructure = ``;
  templeteOutlineTree.forEach((section) => {
    outlineStructure += `Section: ${section.name}\n`;
    section.components.forEach((component) => {
      outlineStructure += `  Component: ${component.name}\n`;
    });
  });

  return outlineStructure;
};

export const getOutlineText = (templeteOutlineTree, outlineSteps) => {
  let outlineText = ``;
  templeteOutlineTree.forEach((section) => {
    outlineText += `Section: ${section.name}\n`;
    section.components.forEach((component) => {
      if (outlineSteps[component.id]) {
        outlineText += `  ${component.name}: ${outlineSteps[component.id]}`; // INFO: we get text with \n at the end
      }
    });
  });
  return outlineText;
};

export const getOutlineComponentsWithValues = (templateComponents, data) => {
  const componentIds = getTemplateComponents(templateComponents).map((component) => component.id);

  return componentIds.reduce((result, id) => {
    // eslint-disable-next-line no-param-reassign
    result[id] = removeWhitespaces(data[id]); // TODO: Let's get rid of \n at backend?
    return result;
  }, {});
};

export const checkIfOutlineIsCompleted = (outlineComponents) =>
  Object.values(outlineComponents).every((value) => value !== '');

export const getComponentById = (templateComponents, id) => templateComponents.find((component) => component.id === id);
