import { useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';

const MAX_STEP_DIFFERENCE_MS = 2000;

export const usePlayerSteps = (steps, timeResolution) => {
  const firstStepClientTime = steps[0]?.clientTime;
  const firstStepTime = new Date(firstStepClientTime);

  return useMemo(() => {
    const mappedSteps = steps
      .map(({ ops, type, prompt, response, clientTime }) => ({
        delta: ops, // TODO: I think it'd be great to unify it and either use ops or delta
        prompt,
        response,
        time: differenceInMilliseconds(new Date(clientTime), firstStepTime),
        type,
      }))
      .reduce((result, item) => {
        const lastItem = result.at(-1) || { time: 0, adjustedTime: 0 };
        const difference = item.time - lastItem.time;
        const adjustedTime =
          lastItem.adjustedTime +
          (difference > MAX_STEP_DIFFERENCE_MS
            ? MAX_STEP_DIFFERENCE_MS
            : difference);

        return [...result, { ...item, adjustedTime }];
      }, [])
      .map(({ adjustedTime, delta, prompt, response, type }) => ({
        delta,
        prompt,
        response,
        time: adjustedTime,
        type,
      }));

    const lastStepTime = mappedSteps.at(-1)?.time || 0;

    return [...mappedSteps, { delta: [], time: lastStepTime + timeResolution }];
  }, [steps, firstStepClientTime]);
};
